<div class="admin-wrapper">

  <div class="scapi-wrapper">

    <div class="message is-fullwidth">

      <div class="message-header">
        <p i18n>Filters</p>
      </div>

      <div class="message-body">
        <div class="is-flex is-flex-direction-row is-flex-wrap-wrap filter-row unisized-labels checkbox-row">
          <div class="label label-select  is-flex is-flex-direction-column" style="width: 700px">
            <span i18n>Object IDs</span>
            <div>
              <textarea class="textarea" type="text" [ngModel]="objectIDsString" 
              rows="5" (ngModelChange)="setObjectIDsString($event)"></textarea>
            </div>
          </div>

          </div>

        <button *ngIf="!loading; else loadingBtn" class="button is-primary" (click)="requestPlots()">
          <strong i18n>Apply filters</strong>
        </button>
        <ng-template #loadingBtn>
          <button class="button is-primary" disabled>
            <div class="loader"></div>&nbsp;
            <strong i18n>Apply filters</strong>
          </button>
        </ng-template>
        &nbsp;
        <button class="button" (click)="resetFilters()" [disabled]="loading">
          <ng-container i18n>Reset filters</ng-container>
        </button>
      </div>
    </div>

    <div class="message is-fullwidth" *ngIf="plotIDs && plotIDs.length > 0 && !loading">
      <div class="columns">
        <div class="column is-italic"> <span i18n>Found following plots and added them to Dashboard:</span> {{plotIDs}} </div>
      </div>
    </div>

  </div>
</div>