import { Injectable } from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {StorageService} from "./storage.service";
import {StatesService} from "./states.service";
import {KeycloakTokenParsed} from "keycloak-js";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Token as issued by the keycloak server.
   * @private
   */
  private token: string | undefined;

  /**
   * The parsed id token as a JavaScript object.
   * @private
   */
  private tokenParsed: KeycloakTokenParsed | undefined;

  constructor(
    private keycloak: KeycloakService,
    private statesService: StatesService,
    private storageService: StorageService
  ) { }

  /**
   * Retrieves the parsed user Token and user States. Also creates an interval for automated periodic token renewal.
   */
  async processUserToken() {
    this.tokenParsed = this.keycloak.getKeycloakInstance().idTokenParsed;

    if (this.tokenParsed) {
      await this.statesService.setStates(this.tokenParsed);
    }

    setInterval(async () => {
      try {
        const refreshed = await this.keycloak.updateToken();
        if (refreshed) {
          console.log('Token refreshed');
        } else {
          console.log('Token not refreshed');
        }
      } catch (e) {
        console.error('Failed to refresh token', e);
      }
    }, 60000);
  }

  /**
   * Logs out the current user.
   */
  async logout() {
    await this.storageService.remove('username');
    await this.storageService.remove('active-state-id');
    await this.keycloak.logout();
  }

  /**
   * Retrieves the current user's username.
   * @return The current user's username
   */
  getUsername(): string | undefined {
    return this.tokenParsed?.['preferred_username'];
  }

  /**
   * Checks if the current user has a certain role
   * @param role - The role to check
   * @return Whether the user hast the given role
   */
  hasRole(role: string): boolean {
    return !!this.tokenParsed && this.tokenParsed['realm_access']?.roles.indexOf(role) !== -1;
  }

  /**
   * Retrieves the roles of the current user.
   * @return The roles of the current user
   */
  getUserRoles(): string[] {
    return this.tokenParsed?.['realm_access']?.roles ? this.tokenParsed['realm_access']['roles'] : [];
  }
}
