<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless" >
  <ul>
    <li [ngClass]="{'is-active': activeTab === 'plots'}">
      <a (click)="setActiveTab('plots')"><span i18n>All Plots</span>&nbsp; <span *ngIf="plotsCount">({{plotsCount.total}})</span></a>
    </li>
    <li *ngIf="!userIsCustomer" [ngClass]="{'is-active': activeTab === 'watchlist'}">
      <a (click)="setActiveTab('watchlist')"><span i18n>Watchlist</span>&nbsp; <span *ngIf="watchlistCount">({{watchlistCount.total}})</span></a>
    </li>
  </ul>
</div>

<ng-container *ngTemplateOutlet="activeTab === 'plots' ? plotsTmpl : watchlistTmpl"></ng-container>

<ng-template #plotsTmpl>
  <app-plot-list-single
    [plots]="plots"
    [plotsCount]="plotsCount"
    [selectedPlotIds]="(selectedPlotIds$ | async)?.plotIds || null"
    [maxHeight]="maxHeight"
    [userIsCustomer]="userIsCustomer"
    (plotSelected)="setSelectedPlot($event)"
    (scrolled)="onScrolled(false)"
    (requestSorting)="setSorting($event, false)"
    (requestFilters)="setFilters($event, false)"
    (requestStatesShown)="setStatesShown($event, false)">
  </app-plot-list-single>
</ng-template>

<ng-template #watchlistTmpl>
  <app-plot-list-single
    [plots]="watchlistPlots"
    [plotsCount]="watchlistCount"
    [selectedPlotIds]="(selectedPlotIds$ | async)?.plotIds || null"
    [maxHeight]="maxHeight"
    (plotSelected)="setSelectedPlot($event)"
    (scrolled)="onScrolled(true)"
    (requestSorting)="setSorting($event, true)"
    (requestFilters)="setFilters($event, true)"
    (requestStatesShown)="setStatesShown($event, true)">
  </app-plot-list-single>
</ng-template>
