import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {Plot, MonitorShortLabels} from "../../model/plot";
import {PlotsService} from "../../services/plots.service";
import {Observable, Subscription, firstValueFrom, map} from "rxjs";
import {LabeledFieldComponent} from "../labeled-field/labeled-field.component";
import {SortMonitorsPipe} from "../../pipes/sort-monitors.pipe";
import {FeatherModule} from "angular-feather";
import {CommonModule} from "@angular/common";
import {selectedPlotsChange} from '../../events';

@Component({
  standalone: true,
  selector: 'app-plot-details',
  templateUrl: './plot-details.component.html',
  imports: [
    CommonModule,
    LabeledFieldComponent,
    SortMonitorsPipe,
    FeatherModule
  ],
  styleUrls: ['./plot-details.component.scss']
})
export class PlotDetailsComponent implements OnInit, OnDestroy {
  @Input() userIsCustomer: boolean = false;

  plot$: Observable<Plot | null> | undefined;
  assessmentTags: any[] = [];

  subtables: any = {};

  subscription: Subscription | undefined;

  math = Math;

  finalResultsExpanded = true;
  resultsExpanded = true;

  expandedFinalResults: {monitor: string; updatedAt: number}[] = [];
  expandedResults: {monitor: string; updatedAt: number}[] = [];

  constructor(private plotsService: PlotsService) {
  }

  async ngOnInit() {
    this.assessmentTags = await firstValueFrom(this.plotsService.getAssessmentTags());
    this.subscription = selectedPlotsChange
      .subscribe(data => {
        if (data.plotIds.length >= 1) {
          this. expandedFinalResults = [];
          this.expandedResults = [];
          this.plot$ = this.plotsService.getPlotDetails(data.plotIds[0]);
          this.plot$?.pipe(
            map(plot => {
              if (this.userIsCustomer) {
                plot?.finalResults.forEach(finalResult => {
                  if (finalResult && finalResult.updatedAt) {
                    this.toggleExpandFinalResult(finalResult.monitor, new Date(finalResult.updatedAt))
                  }
                  });
              }
              return plot;
            })
          ).subscribe();
        } else {
          this.plot$ = undefined;
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  hasChildren(obj: any): boolean {
    return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0;
  }

  isArray(obj: any): boolean {
    return obj.hasOwnProperty('length');
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  expand(key: string) {
    this.subtables[key] = !this.subtables[key];
  }

  isExpanded(key: string): boolean {
    return !!this.subtables[key];
  }

  isFinalResultExpanded(monitor: string | undefined, updatedAt: Date | undefined) {
    const index = this.expandedFinalResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt?.valueOf());
    return updatedAt && index !== -1;
  }

  toggleExpandFinalResults() {
    this.finalResultsExpanded = !this.finalResultsExpanded;
  }

  toggleExpandResults() {
    this.resultsExpanded = !this.resultsExpanded;
  }

  toggleExpandFinalResult(monitor: string | undefined, updatedAt: Date | undefined) {
    if (updatedAt) {
      const index = this.expandedFinalResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt.valueOf());
      if (index !== -1) {
        this.expandedFinalResults.splice(index, 1);
      } else {
        this.expandedFinalResults.push({monitor: monitor || '', updatedAt: updatedAt.valueOf()});
      }
    }
  }

  isResultExpanded(monitor: string | undefined, updatedAt: Date | undefined) {
    const index = this.expandedResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt?.valueOf());
    return updatedAt && index !== -1;
  }

  toggleExpandResult(monitor: string | undefined, updatedAt: Date | undefined) {
    if (updatedAt) {
      const index = this.expandedResults.findIndex(result => result.monitor === monitor && result.updatedAt === updatedAt.valueOf());
      if (index !== -1) {
        this.expandedResults.splice(index, 1);
      } else {
        this.expandedResults.push({monitor: monitor || '', updatedAt: updatedAt.valueOf()});
      }
    }
  }

  getMonitorShortLabel(field: string | undefined) {
    if (field && ((MonitorShortLabels as any)[field])) {
      return (MonitorShortLabels as any)[field];
    } else {
      return field;
    }
  }

  getCodeColor(code: number | undefined) {
    if (code === undefined) {
      return 'has-background-white';
    }
    const codeCategory = code ? parseInt(code.toString().slice(-2), 10) : 0;
    if (codeCategory < 10) {
      return 'has-background-success';
    } else if (codeCategory >= 10 && codeCategory < 90) {
      return 'has-background-warning';
    } else {
      return 'has-background-danger';
    }
  }
  protected readonly Math = Math;
}
