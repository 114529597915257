import {State, WMSInfo} from './model';

/**
 * Current app version
 */
export const dashboardVersion: string = '2024.10.1';

/**
 * This enumeration should be used to access all projections within the application.
 */
export enum projections {
  wgs84 = 'EPSG:4326',
  utm32n = 'EPSG:25832',
  webMercator = 'EPSG:3857'
}

/**
 * An array that contains WMS metadata
 */
export const wmsLayers: WMSInfo[] = [{
//   baseUrl: "https://gds-srv.hessen.de/wmts-dop/service",
//   licence: "dl-zero-de/2.0",
//   licenceUrl: "https://www.govdata.de/dl-de/zero-2-0",
//   attribution: "©2023 Hessische Verwaltung für Bodenmanagement und Geoinformation: Digitale Orthophotos",
//   state: "HE",
//   type: "WMTS"
// }, {
  baseUrl: "https://gds-srv.hessen.de/cgi-bin/lika-services/ogc-free-images.ows",
  licence: "dl-zero-de/2.0",
  licenceUrl: "https://www.govdata.de/dl-de/zero-2-0",
  attribution: "©2023 Hessische Verwaltung für Bodenmanagement und Geoinformation: Digitale Orthophotos",
  state: "HE",
  type: "WMS",
  layer: "he_dop_rgb"
}, {
  baseUrl: "https://opendata.lgln.niedersachsen.de/doorman/noauth/dop_wms",
  licence: "CC BY 4.0",
  licenceUrl: "https://creativecommons.org/licenses/by/4.0/",
  attribution: "Auszug aus den Geodaten des Landesamtes für Geoinformation und Landesvermessung Niedersachsen, ©2024",
  state: "NI",
  type: "WMS",
  layer: "ni_dop20"
}, {
//   baseUrl: "https://www.wmts.nrw.de/geobasis/wmts_nw_dop",
//   licence: "dl-zero-de/2.0",
//   licenceUrl: "https://www.govdata.de/dl-de/zero-2-0",
//   attribution: "Geobasis NRW ©2023",
//   state: "NW",
//   type: "WMTS",
//   layer: "nw_dop"
// }, {
  baseUrl: "https://www.wms.nrw.de/geobasis/wms_nw_dop",
  licence: "dl-zero-de/2.0",
  licenceUrl: "https://www.govdata.de/dl-de/zero-2-0",
  attribution: "Geobasis NRW ©2023",
  state: "NW",
  type: "WMS",
  layer: "nw_dop_rgb"
}, {
  baseUrl: "https://geo4.service24.rlp.de/wms/rp_dop40.fcgi",
  licence: "dl-by-de/2.0",
  licenceUrl: "https://www.govdata.de/dl-de/by-2-0",
  attribution: "©GeoBasis-DE / LVermGeoRP (2023)",
  state: "RP",
  type: "WMS",
  layer: "rp_dop40"
}, {
  baseUrl: "https://service.gdi-sh.de/WMS_SH_DOP20col_OpenGBD",
  licence: "CC BY 4.0",
  licenceUrl: "https://creativecommons.org/licenses/by/4.0",
  attribution: "© GeoBasis-DE/LVermGeo SH",
  state: "SH",
  type: "WMS",
  layer: "sh_dop20_rgb"
}];

/**
 * An array that contains state metadata
 */
export const states: State[] = [{
  id: 'HE',
  name: 'Hessen',
  extent: [825843, 6317390, 1168860, 6756770]
}, {
  id: 'NI',
  name: 'Niedersachsen',
  extent: [724219, 6648420, 1308450, 7198250]
}, {
  id: 'NW',
  name: 'Nordrhein-Westfalen',
  extent: [615841, 6492318, 1077968, 6904206]
}, {
  id: 'RP',
  name: 'Rheinland-Pfalz',
  extent: [672121, 6257571, 959318, 6621453]
}
// , {
//   id: 'SH',
//   name: 'Schleswig-Holstein',
//   extent: [875919, 7049693, 1259381, 7373010]
// }
];

/**
 * An array that contains ncode categories
 */
export const nCodeCategories: {id: string; label: string}[] = [{
  id: 'DGL',
  label: 'Grassland (Dauergrünland)'
}, {
  id: 'DK',
  label: 'Permanent culture (Dauerkultur)'
}, {
  id: 'Erbe',
  label: 'category based on neighbouring parcel (Flächenkategorie gemäß benachbarten oder zugehörigen Schlag)'
}, {
  id: 'F',
  label: 'natural reserve (Naturschutz)'
}, {
  id: 'SK',
  label: 'Summer crop (Sommerkultur)'
}, {
  id: 'WK',
  label: 'Winter crop (Winterkultur)'
}, {
  id: 'S',
  label: 'Other (sonstige Flächen)'
}];

export const defaultComponentPositions = {
  'left-top': 'table',
  'left-bottom': 'editor',
  'center': 'gallery',
  'right-top': 'map',
  'right-bottom': 'details',
  'bottom-left': 'chart',
  'bottom-right': 'events',
}

export const maxIdleTime = 1200000;
