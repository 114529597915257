import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {firstValueFrom, map, Observable, tap} from "rxjs";
import {PlotsService} from "../../services/plots.service";
import {PlotShort, PlotShortAttribute, PlotShortFieldLabels, PlotShortLike} from "../../model/plot";
import {UtilService} from "../../services/util.service";
import {GraphqlService} from "../../services/graphql.service";
import {nCodeCategories} from "../../constants";
import moment from "moment";
import {FormsModule} from "@angular/forms";
import {FeatherModule} from "angular-feather";
import {ComboSelectComponent} from "../combo-select/combo-select.component";
import {PlotListMultipleComponent} from "../plot-list-multiple/plot-list-multiple.component";
import {CommonModule} from "@angular/common";
import {StatesService} from "../../services/states.service";
import {showNotification} from '../../events';
import {Assignment} from '../../model';

@Component({
  standalone: true,
  selector: 'app-scapi-assignment',
  templateUrl: './scapi-assignment.component.html',
  imports: [
    CommonModule,
    FormsModule,
    FeatherModule,
    ComboSelectComponent,
    PlotListMultipleComponent
  ],
  styleUrls: ['./scapi-assignment.component.scss']
})
export class ScapiAssignmentComponent implements OnInit {

  classes$: Observable<any> | undefined;
  eftasZones$: Observable<any> | undefined;
  monitors$: Observable<any> | undefined;
  nCodes$: Observable<any> | undefined;
  operatorsScapi$: Observable<any> | undefined;
  operatorsSfb$: Observable<any> | undefined;

  tagTree = [{
    block: 'Kulturprüfung',
    tags: [{
      name: 'nutzung_zusaetzlich',
      label: 'Mehrere Nutzungen (50)',
    }, {
      name: 'nicht_bewertbar',
      label: 'Nutzung nicht bewertbar (80)',
    }, {
      name: 'nutzung_unklar',
      label: 'Nutzung unklar',
    }, {
      name: 'gewaechshaus_folientunnel',
      label: 'Gewächshaus / Folientunnel'
    }]
  }, {
    block: 'Tätigkeitsprüfung',
    tags: [{
      name: 'taetigkeit_mahd_mulchen',
      label: 'Mahd/Mulchen',
    }, {
      name: 'taetigkeit_beweidung',
      label: 'Beweidung',
    }, {
      name: 'taetigkeit_umbruch',
      label: 'Umbruch',
    }, {
      name: 'taetigkeit_aussaat',
      label: 'Saat/Neuansaat',
    }, {
      name: 'taetigkeit_andere',
      label: 'Erfüllt, aber nicht eindeutig',
    }, {
      name: 'taetigkeit_nicht_loesbar',
      label: 'In sCapi nicht lösbar',
    }, {
      name: 'taetigkeit_keine',
      label: 'Dauerhaft nicht genutzt/verbuscht',
    }, {
      name: 'bisher_nicht_loesbar',
      label: 'Bisher nicht lösbar',
    },]
  }, {
    block: 'Sperrzeitraum',
    tags: [{
      name: 'taetigkeit_sperrzeitraum_bestaetigt',
      label: 'Mindesttätigkeit im Sperrzeitraum bestätigt',
    }, {
      name: 'taetigkeit_sperrzeitraum_revidiert',
      label: 'keine Mindesttätigkeit im Sperrzeitraum',
    }]
  }, {
    block: 'NBF-Prüfung',
    tags: [{
      name: 'nbf',
      label: 'NBF vorgefunden',
    }, {
      name: 'nbf_revidiert',
      label: 'keine NBF vorgefunden',
    }]
  }, {
    block: 'Fotos',
    tags: [{
      name: 'foto_verwendet',
      label: 'Foto verwendet',
    }]
  }];

  filtersMinimized = false;
  loading = false;
  plots: PlotShort[] = [];
  plotIDs: number[] = [];

  featuresCount: number = -1;

  pageSize: number = 500;

  offset: number = 0;

  filters: any = {
    assessmentScapi: {tags: [], nCodeMeasured: [], comment: ''},
    assignedScapiUsername: [],
    assignedSfbUsername: [],
    eftasZones: [],
    id: [],
    ncode: {category: [], classCode: [], code: []},
    results: {monitor: [], methodList: {_in: 'SCAPI'}},
    finalResults: {resultCode: []}
  }

  checkPhoto: boolean | null = null;
  checkPhotoRequested: boolean | null = null;
  checkSfb: boolean | null = null;
  checkNCodesMismatch: boolean | null = null;

  selectState: 'new' | 'in_progress' | 'finished' | null = null;

  assignment: Assignment = {
    plotIds: [],
    method: 'scapi',
    operator: '',
    target: 'task-list'
  };

  categories = nCodeCategories;
  selectedFields: PlotShortAttribute[] = ['id', 'area', 'eftasZone', 'nCodeDeclared', 'classCode', 'scapiUser', 'watchlistUsers', 'nCodeMeasured', 'state', 'tags', 'scapiComments'];
  unselectedFields: PlotShortAttribute[] = ['eftasId', 'latestScapiAssessment']; // , 'ALL_NBF', 'ALL_NUTZART', 'BP_MINDEST_BRA_STR', 'BP_MINDEST_DGL', 'SPERR_BRA'
  scapiOperatorMode: 'task-list' | 'watch-list' = 'task-list';
  isFieldDropdownActive = false;

  sortableFields: PlotShortAttribute[] = ['id', 'area', 'eftasZone', 'nCodeDeclared', 'classCode', 'scapiUser', 'nCodeMeasured', 'state', 'eftasId', 'latestScapiAssessment'];
  sorting: { direction: "ASC" | "DESC"; field: PlotShortAttribute } = {direction: 'ASC', field: 'id'};

  today = moment().format('YYYY-MM-DD');
  fromDate: any;
  toDate: any;

  get objectIDsString(): string {
    return this.filters.id.join('; ');
  };

  get resultCodesString(): string {
    return this.filters.finalResults.resultCode.join('; ');
  }

  constructor(
    public plotsService: PlotsService,
    private statesService: StatesService,
    private graphql: GraphqlService,
    private util: UtilService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.classes$ = this.graphql.queryField({
      field: 'classificationcode',
      attributes: ['code', 'label']
    }).pipe(map(classes =>
      classes.sort((classA: any, classB: any) => classA.code - classB.code)
    ));

    this.eftasZones$ = this.graphql.queryField({field: 'eftasZone', attributes: ['name']}).pipe(map(eftasZone =>
      eftasZone.sort((zoneA: any, zoneB: any) => {
        if (zoneA.name < zoneB.name) {
          return -1;
        } else if (zoneA.name > zoneB.name) {
          return 1;
        } else {
          return 0;
        }
      })
    ));

    this.monitors$ = this.graphql.queryField({
      field: 'monitor',
      attributes: ['internalName', 'name']
    }).pipe(
      map(monitors =>
        monitors.sort((monitorA: any, MonitorB: any) => {
          if (monitorA.name < MonitorB.name) {
            return -1;
          } else if (monitorA.name > MonitorB.name) {
            return 1;
          } else {
            return 0;
          }
        })
      ),
      tap((monitors: any[]) =>
        monitors.forEach(monitor => {
          if (monitor.name && monitor.name !== '') {
            this.unselectedFields.push(monitor.name)
          }
        })
      )
    );

    this.nCodes$ = this.graphql.queryField({field: 'ncode', attributes: ['code', 'codeLabel']}).pipe(map(nCodes =>
      nCodes.sort((codeA: any, codeB: any) => codeA.code - codeB.code)
    ));

    const stateID = this.statesService.getActiveState()?.id.toLowerCase();
    this.operatorsSfb$ = this.graphql.queryField({
      field: 'users',
      attributes: ['username'],
      filter: `{groups: [sfb_${stateID}]}`}
    );

    this.operatorsScapi$ = this.graphql.queryField({
      field: 'users',
      attributes: ['username'],
      filter: '{groups: [scapi]}'
    }).pipe(
      map((users: {username: string}[]) => {
        return [{username: 'nicht zugewiesen'}, ...users];
      })
    );
  }

  setObjectIDsString(objectIDsString: string) {
    if (objectIDsString.match(/[\d\s;]+/)) {
      this.filters.id = objectIDsString.split(';')
        .map(objectID => Number.parseInt(objectID.trim(), 10))
        .filter(objectId => !isNaN(objectId));
    } else if (!objectIDsString.match(/^\s*$/)) {
      showNotification.next({
        title: 'Fehler',
        message: 'Objekt-IDs dürfen nur aus Zahlen bestehen. Bei der Eingabe mehrerer Objekt-IDs müssen diese durch Semikolons voneinander getrennt werden.',
        class: 'is-danger'
      });
    }
  }

  setResultCodesString(resultCodesString: string) {
    if (resultCodesString.match(/[\d\s;]+/)) {
      this.filters.finalResults.resultCode = resultCodesString.split(';')
        .map(resultCode => Number.parseInt(resultCode.trim(), 10))
        .filter(resultCode => !isNaN(resultCode));
    } else if (!resultCodesString.match(/^\s*$/)) {
      showNotification.next({
        title: 'Fehler',
        message: 'Ergebniscodes dürfen nur aus Zahlen bestehen. Bei der Eingabe mehrerer Ergebniscodes müssen diese durch Semikolons voneinander getrennt werden.',
        class: 'is-danger'
      });
    }
  }

  setAssignedScapiUsername(usernames: string[]) {
    if (usernames.length > 1 && usernames.indexOf('nicht zugewiesen') !== -1) {
      this.filters.assignedScapiUsername = ['nicht zugewiesen'];
    } else {
      this.filters.assignedScapiUsername = usernames;
    }
  }

  toggleMinimizeFilters() {
    this.filtersMinimized = !this.filtersMinimized;
  }

  async requestPlots(all: boolean = false) {
    if (this.offset === 0) {
      this.loading = true;
    }

    // remove unused filters
    const filters: any = JSON.parse(JSON.stringify(this.filters));

    if (this.checkNCodesMismatch !== null) {
      filters.assessmentScapi.exists = true;
      filters.assessmentScapi.nCodeDeclaredNotMeasured = this.checkNCodesMismatch;
    }

    if (filters.assessmentScapi.tags.length === 0) {
      delete filters.assessmentScapi.tags;
    } else {
      filters.assessmentScapi.index = -1;
    }
    if (filters.assessmentScapi.nCodeMeasured.length === 0) {
      delete filters.assessmentScapi.nCodeMeasured;
    }
    if (filters.assessmentScapi.comment.length === 0) {
      delete filters.assessmentScapi.comment;
    } else {
      filters.assessmentScapi.exists = true;
    }
    if (this.selectState !== null) {
      switch (this.selectState) {
        case "new":
          filters.assessmentScapi.exists = false;
          break;
        case "in_progress":
          filters.assessmentScapi.index = -1;
          filters.assessmentScapi.finished = false;
          break;
        case "finished":
          filters.assessmentScapi.index = -1;
          filters.assessmentScapi.finished = true;
          break;
      }
    }
    if (this.fromDate || this.toDate) {
      filters.assessmentScapi.index = -1;
      filters.assessmentScapi.assessmentDate = {};
      if (this.fromDate) {
        const m = moment(this.fromDate, 'YYYY-MM-DD');
        filters.assessmentScapi.assessmentDate._minInclusive = m.toISOString(true);
      }
      if (this.toDate) {
        const m = moment(this.toDate, 'YYYY-MM-DD');
        m.add(1, 'd');
        filters.assessmentScapi.assessmentDate._maxExclusive = m.toISOString(true);
      }
    }
    if (Object.keys(filters.assessmentScapi).length === 0) {
      delete filters.assessmentScapi;
    }
    if (filters.assignedScapiUsername.length === 0) {
      delete filters.assignedScapiUsername;
    } else if (this.scapiOperatorMode === 'watch-list') {
      filters.assignedWatchlistUsername = JSON.parse(JSON.stringify(filters.assignedScapiUsername));
      delete filters.assignedScapiUsername;
    }
    if (filters.assignedSfbUsername.length === 0) {
      delete filters.assignedSfbUsername;
    }
    if (filters.eftasZones.length === 0) {
      delete filters.eftasZones;
    }
    if (filters.id.length === 0) {
      delete filters.id;
    }
    delete filters.ncode.category;
    if (filters.ncode.classCode.length === 0) {
      delete filters.ncode.classCode;
    }
    if (filters.ncode.code.length === 0) {
      delete filters.ncode.code;
    }
    if (Object.keys(filters.ncode).length === 0 && this.filters.ncode.category.length === 0) {
      delete filters.ncode;
    }
    if (filters.results.monitor.length === 0) {
      delete filters.results.monitor;
    }
    if (filters.finalResults.resultCode.length === 0) {
      delete filters.finalResults;
    }
    if (this.checkSfb !== null) {
      if (this.checkSfb) {
        filters.results.methodList._intersect = 'SFB';
      } else {
        filters.results.methodList._notIn = 'SFB';
      }
    }

    let filterString = this.util.stringifyGraphQL(filters);
    filterString = filterString.replace(/\["nicht zugewiesen"\]/, '[]');
    filterString = filterString.replace(/_in:"SCAPI"/, '_in:SCAPI');
    filterString = filterString.replace(/_intersect:"SFB"/, '_intersect:SFB');
    filterString = filterString.replace(/_notIn:"SFB"/, '_notIn:SFB');


    // add category
    if (this.filters.ncode.category.length >= 1) {
      let categoryString = `category:${JSON.stringify(this.filters.ncode.category)}`.replace(/"/g, '');
      if (filters.ncode.classCode || filters.ncode.code) {
        categoryString += ',';
      }
      const index = filterString.indexOf('ncode:{') + 7;
      filterString = this.util.insertString(filterString, categoryString, index);
    }

    // sfb checkbox
    if (this.checkSfb) {
      filterString = filterString.replace(/method:SCAPI/, 'method:SFB');
    }

    // photo requested checkbox
    if (this.checkPhotoRequested !== null) {
      let finalResultsString;
      if (this.checkPhotoRequested) {
        finalResultsString = 'finalResults:{photoProofList:{_in:[REQUEST_FARMER]}}';
      } else {
        finalResultsString = 'finalResults:{photoProofList:{_notIn:[REQUEST_FARMER]}}';
      }
      if (filterString !== '{}') {
        finalResultsString = ',' + finalResultsString;
      }
      const index = filterString.lastIndexOf('}');
      filterString = this.util.insertString(filterString, finalResultsString, index);
    }


    this.graphql.queryField({
      field: 'featuresCount',
      filter: filterString
    }).pipe(
      map((response: any) => response)
    ).subscribe((count: number) => {
      this.featuresCount = count;
    });

    const sorting: any = Object.assign({}, this.sorting);
    if (this.sorting.field === 'classCode') {
      sorting.field = 'nCodeDeclared.classCode';
    }
    else if (this.sorting.field === 'state') {
      sorting.field = 'assessmentScapi.finished';
    }
    else if (this.sorting.field === 'nCodeMeasured') {
      sorting.field = 'assessmentScapi.nCodeMeasured';
    }
    else if (this.sorting.field === 'scapiUser') {
      sorting.field = 'assignmentScapi.username';
    }
    else if (this.sorting.field === 'latestScapiAssessment') {
      sorting.field = 'assessmentScapi.assessmentDate';
    }
    let sortingString = `sort: [${this.util.stringifyGraphQL(sorting).replace(/direction:"(ASC|DESC)"/, 'direction:$1')}]`;
    if (sorting.field !== 'id') {
      const index = sortingString.lastIndexOf(']');
      sortingString = this.util.insertString(sortingString, ', {field: "id", direction: ASC}', index);
    }

    let index = filterString.lastIndexOf('}');
    filterString = this.util.insertString(filterString, sortingString, index);
    index = filterString.lastIndexOf('}');
    if (!all) {
      filterString = this.util.insertString(filterString, `count: ${this.pageSize}, offset: ${this.offset}`, index);
    }

    const plots$ = this.graphql.queryField({
      field: 'features', attributes: [
        'id',
        'nCodeDeclared {code, codeLabel, classCode}',
        'area',
        'eftasZone',
        'assignmentScapi {username}',
        'assignmentWatchlist {username}',
        'assessmentScapi {assessmentDate, nCodeMeasured, tags, comment, finished}',
        'finalResults {monitor, resultCode}'
      ],
      filter: filterString
    })
      .pipe(
        map((response: any) => {
          return response
            .filter((plotShortLike: any) => plotShortLike && plotShortLike.id)
            .map((plotShortLike: PlotShortLike) => new PlotShort(plotShortLike));
        })
      );

    try {
      const plots = await firstValueFrom(plots$);
      if (this.offset === 0) {
        this.plots = plots;
        this.plotIDs = plots.map((plot: PlotShort) => plot.id);
        this.assignment = {
          plotIds: [],
          method: 'scapi',
          operator: '',
          target: 'task-list'
        };
        this.filtersMinimized = true;
        this.loading = false;
      } else {
        this.plots = this.plots.concat(plots);
        this.plotIDs = this.plotIDs.concat(plots.map((plot: PlotShort) => plot.id));
      }
    } catch (e) {
      this.loading = false;
      const message = !!e ? e.toString() : 'Beim Laden der Schläge ist ein Fehler aufgetreten.';
      showNotification.next({title: 'Error', message, class: 'is-danger'})
    }
  }

  async onScrolled() {
    this.offset += this.pageSize;
    await this.requestPlots();
  }

  onPlotsSelected(plotIds: number[]) {
    plotIds.forEach(plotId => {
      const index = this.assignment.plotIds.indexOf(plotId);
      if (index === -1) {
        this.assignment.plotIds.push(plotId);
      } else {
        this.assignment.plotIds.splice(index, 1);
      }
    })
  }

  async assignOperator() {
    try {
      // first remove assignment from plots that are assigned to different operators
      let errors;
      const assignedPlots = this.plots.filter(plot => plot.scapiUser && plot.scapiUser !== this.assignment.operator && this.assignment.plotIds.indexOf(plot.id) !== -1);
      if (assignedPlots.length >= 1 && this.assignment.target !== 'watch-list') {
        const response = await this.plotsService.unassignOperator(assignedPlots);
        errors = response.errors;
      }
      if (errors && errors.length && errors.length >= 1) {
        showNotification.next({
          title: 'Fehler',
          message: 'Bei der Freigabe bereits zugewiesener Schläge ist ein Fehler aufgetreten.'
        })
      } else {
        // assign any plots not already assigned to the chosen operator
        this.assignment.plotIds = this.plots
          .filter(plot => (!plot.scapiUser || plot.scapiUser !== this.assignment.operator) && this.assignment.plotIds.indexOf(plot.id) !== -1)
          .map(plot => plot.id);

        const response = await firstValueFrom(this.plotsService.assignOperator(this.assignment));
        if (response) {
          this.updateData();
          showNotification.next({
            title: 'Zuweisung erfolgreich',
            message: `Die Schläge wurden Bearbeiter*in ${this.assignment.operator} erfolgreich zugewiesen.`,
            class: 'is-success'
          });
        }
      }

    } catch (e) {
      showNotification.next({
        title: 'Fehler',
        message: 'Bei der Zuweisung der Schläge ist ein Fehler aufgetreten.',
        class: 'is-error'
      });
    }
  }

  private updateData() {
    this.plots = this.plots.map(plot => {
      if (this.assignment.plotIds.indexOf(plot.id) !== -1) {
        if (this.assignment.target === 'watch-list') {
          if (plot.watchlistUsers) {
            plot.watchlistUsers.push(this.assignment.operator);
          } else {
            plot.watchlistUsers = [this.assignment.operator];
          }
        } else {
          plot.scapiUser = this.assignment.operator;
        }
      }
      return plot;
    });
    this.cdRef.markForCheck();
  }

  resetFilters() {
    this.filters = {
      assessmentScapi: {tags: [], nCodeMeasured: [], comment: ''},
      assignedScapiUsername: [],
      assignedSfbUsername: [],
      eftasZones: [],
      id: [],
      ncode: {category: [], classCode: [], code: []},
      results: {monitor: [], methodList: {_in: 'SCAPI'}},
      finalResults: {resultCode: []}
    }

    this.fromDate = null;
    this.toDate = null;
    this.selectState = null;
    this.checkSfb = null;
    this.checkPhoto = null;
    this.checkPhotoRequested = null;
    this.checkNCodesMismatch = null;

    this.plots = [];
  }

  showCommentsInfo(event: MouseEvent) {
    showNotification.next({
      title: 'Filtern nach Kommentaren',
      message: 'Gesuchte Begriffe werden durch ein Leerzeichen voneinander getrennt. ' +
        'Wenn entweder ein Begriff oder ein anderer gesucht werden sollen müssen diese Begriffe durch das Wort "or" getrennt werden. ' +
        'Soll ein Begriff nicht im Ergebnis vorkommen muss ihm ein "-" vorangestellt werden.',
      class: 'is-info'
    });
  }

  selectAll() {
    this.assignment.plotIds = this.plotIDs;
  }

  selectNone() {
    this.assignment.plotIds = [];
  }

  invertSelection() {
    this.assignment.plotIds = this.plotIDs.filter(plotID => this.assignment.plotIds.indexOf(plotID) === -1);
  }

  async setSorting(sorting: { direction: "ASC" | "DESC"; field: PlotShortAttribute }) {
    this.offset = 0;
    this.sorting = sorting;
    await this.requestPlots();
  }

  selectField(field: PlotShortAttribute) {
    this.unselectedFields.splice(this.unselectedFields.indexOf(field), 1);
    this.selectedFields.push(field);
    this.hideSelectableFields();
  }

  unselectField(event: MouseEvent, field: PlotShortAttribute) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedFields.splice(this.selectedFields.indexOf(field), 1);
    this.unselectedFields.push(field);
  }

  showSelectableFields(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isFieldDropdownActive = true;
  }

  hideSelectableFields() {
    this.isFieldDropdownActive = false;
  }

  getFieldLabel(field: string) {
    if ((PlotShortFieldLabels as any)[field]) {
      return (PlotShortFieldLabels as any)[field];
    } else {
      return field;
    }
  }

  async exportAsCSV() {
    const createCSV = (plots: PlotShort[]): string => {
      let csv = '';
      this.selectedFields.forEach(field => {
        csv += `${(PlotShortFieldLabels as any)[field] || field};`;
      });
      csv = csv.replace(/;$/, '\n');
      plots.forEach(plot => {
        this.selectedFields.forEach(field => {
          let value = plot[field];
          if (Array.isArray(value)) {
            value = value.join(', ');
          }
          if (typeof value === 'string') {
            value = `"${value.replace('\n', ' ')}"`;
          }
          if (value) {
            csv += `${value};`;
          } else {
            csv += ';';
          }
        });
        csv = csv.replace(/;$/, '\n');
      });
      return csv;
    };

    const downloadCSV = (content: string) => {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:application/csv;charset=utf-8,' + encodeURIComponent(content));
      element.setAttribute('download', 'plots.csv');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

    this.loading = true;
    const filter = this.util.stringifyGraphQL({
      id: this.assignment.plotIds
    });
    // get all selected plots
    const plots$ = this.graphql.queryField({
      field: 'features', attributes: [
        'id',
        'nCodeDeclared {code, codeLabel, classCode}',
        'area',
        'eftasZone',
        'assignmentScapi {username}',
        'assignmentWatchlist {username}',
        'assessmentScapi {nCodeMeasured, tags, comment, finished}',
        'finalResults {monitor, resultCode}'
      ],
      filter
    }).pipe(
      map((response: any) => {
        return response
          .filter((plotShortLike: any) => plotShortLike && plotShortLike.id)
          .map((plotShortLike: PlotShortLike) => new PlotShort(plotShortLike));
      })
    );
    try {
      const plots = await firstValueFrom(plots$);
      const csv = createCSV(plots);
      downloadCSV(csv);
    } catch (e) {
      this.loading = false;
      const message = !!e ? e.toString() : 'Beim Laden der Schläge ist ein Fehler aufgetreten.';
      showNotification.next({title: 'Error', message, class: 'is-danger'})
    }
  }

  async setPageSize(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.offset = 0;
      await this.requestPlots();
    }
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
