import {Component, OnInit} from '@angular/core';
import {firstValueFrom, map} from "rxjs";
import {PlotsService} from "../../services/plots.service";
import {PlotShort, PlotShortLike} from "../../model/plot";
import {UtilService} from "../../services/util.service";
import {GraphqlService} from "../../services/graphql.service";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {showNotification} from '../../events';
import {StorageService} from '../../services/storage.service';

@Component({
  standalone: true,
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  imports: [
    CommonModule,
    FormsModule
  ],
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {
  loading = false;
  plotIDs: number[] = [];

  filters: any = {
    id: []
  }

  get objectIDsString(): string {
    return this.filters.id.join('; ');
  };

  constructor(
    public plotsService: PlotsService,
    private graphql: GraphqlService,
    private util: UtilService,
    private storageService: StorageService
  ) {
  }

  async ngOnInit() {
    const customerFilter = await this.storageService.get('customerFilter') as string;
    if (customerFilter) {
      this.filters = JSON.parse(customerFilter);
    }
  }

  setObjectIDsString(objectIDsString: string) {
    if (objectIDsString.match(/[\d\s;]+/)) {
      this.filters.id = objectIDsString.split(';')
        .map(objectID => Number.parseInt(objectID.trim(), 10))
        .filter(objectId => !isNaN(objectId));
      if (this.filters.id.length > 20) {
        showNotification.next({
          title: 'Fehler',
          message: 'Maximale Anzahl der Objekt-IDs ist 20.',
          class: 'is-warning'
        });
      }
    } else if (!objectIDsString.match(/^\s*$/)) {
      showNotification.next({
        title: 'Fehler',
        message: 'Objekt-IDs dürfen nur aus Zahlen bestehen. Bei der Eingabe mehrerer Objekt-IDs müssen diese durch Semikolons voneinander getrennt werden.',
        class: 'is-danger'
      });
    }
  }


  async requestPlots(all: boolean = true) {
    this.loading = true;

    // remove unused filters
    const filters: any = JSON.parse(JSON.stringify(this.filters));

    if (filters.id.length === 0) {
      delete filters.id;
    }

    let filterString = this.util.stringifyGraphQL(filters);

    const plots$ = this.graphql.queryField({
      field: 'features', attributes: [
        'id'
      ],
      filter: filterString
    })
      .pipe(
        map((response: any) => {
          return response
            .filter((plotShortLike: any) => plotShortLike && plotShortLike.id)
            .map((plotShortLike: PlotShortLike) => new PlotShort(plotShortLike));
        })
      );

    try {
      const plots = await firstValueFrom(plots$);
      this.plotIDs = plots.map((plot: PlotShort) => plot.id);
      console.log('received plots', plots, this.filters);
      await this.storageService.set('customerFilter', JSON.stringify(this.filters));
      this.loading = false;
    } catch (e) {
      this.loading = false;
      const message = !!e ? e.toString() : 'Beim Laden der Schläge ist ein Fehler aufgetreten.';
      showNotification.next({title: 'Error', message, class: 'is-danger'})
    }
  }


  async resetFilters() {
    this.filters = {
      id: [],
    }
    this.plotIDs = [];
    await this.storageService.remove('customerFilter');
  }
 

  preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

}


// 6523526; 6498187; 6523470; 6652080

