import { Component, OnInit, NgZone, CUSTOM_ELEMENTS_SCHEMA, ViewChild, Input } from '@angular/core';
import { Observable } from "rxjs";
import { PlotsService } from "../../services/plots.service";
import { PlotPhotoResponse, PlotPhotosData, ClassificationLabels, PhotoClassificationResult } from "../../model/plot";
import { StatesService } from "../../services/states.service";
import { AuthImagePipe } from "../../pipes/auth-image.pipe";
import { FeatherModule } from "angular-feather";
import { ImageModalComponent } from "./image-modal/image-modal.component";
import { CommonModule } from "@angular/common";
import { Swiper, SwiperOptions } from "swiper/types";
import { SwiperDirective } from "../../directives/swiper.directive";
import { plotImageSelected, selectedPhotoChange, selectedPlotsChange, showNotification } from '../../events';
import { BooleanPipe } from "../../pipes/boolean.pipe";

@Component({
  standalone: true,
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  imports: [
    BooleanPipe,
    CommonModule,
    AuthImagePipe,
    FeatherModule,
    ImageModalComponent,
    SwiperDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  @Input() userIsCustomer: boolean = false;

  @ViewChild(SwiperDirective) swiper: SwiperDirective | undefined;

  images: any[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  selectedImage: any;
  activeTab: 'satellite' | 'photos' = 'satellite';
  photoData: PlotPhotosData[] = [];
  activePhoto: PlotPhotosData | null = null;

  photoServerUrl: string = '';
  showImageModal: boolean = false;

  currentSlideIndex: number = 0;
  rotated: number = 0;

  swiperOptions: SwiperOptions = {
    keyboard: {
      enabled: true
    },
    navigation: true,
    pagination: true,
    scrollbar: true,
    slidesPerView: 1,
    zoom: true
  };

  constructor(
    private plotsService: PlotsService,
    private ngZone: NgZone,
    private statesService: StatesService,
  ) {
  }

  /**
   * Initializes the component and subscribes to the `selectedPlotsChange` observable.
   * When the `selectedPlotsChange` emits a new value, it resets the photo data and
   * retrieves the photo server data for the first plot ID in the `plotIds` array.
   *
   * @return {Promise<void>} A promise that resolves when the initialization is complete.
   */
  async ngOnInit() {
    selectedPlotsChange
      .subscribe(async data => {
        this.resetPhotoData();
        if (data.plotIds.length >= 1) {
          const stateId = this.statesService.getActiveState()?.id;
          const allPhotos$ = this.plotsService.getPhotoServerData(stateId, data.plotIds[0]);
          allPhotos$.subscribe({
            next: this.handlePhotoData.bind(this),
            error: this.handleError.bind(this)
          });
        }
      });
  }

  private resetPhotoData() {
    this.photoData = [];
    this.activePhoto = null;
    this.rotated = 0;
  }

  /**
   * Handles the photo data by sorting, filtering, and performing additional actions.
   *
   * @param {PlotPhotoResponse} data - The data to be processed.
   * @return {void} This function does not return anything.
   */
  private handlePhotoData(data: PlotPhotoResponse) {
    const sortedPhotos = data.content.sort((a, b) => Number(a.detail) - Number(b.detail));
    const sfb = sortedPhotos.filter(photo => photo.source === 'SFB');
    const onsite = sortedPhotos.filter(photo => photo.source !== 'SFB');
    this.photoData = sfb.concat(onsite);

    this.activePhoto = this.photoData[0];
    this.slideToFirstSlide();
  }

  private slideToFirstSlide() {
    if (this.swiper) {
      this.swiper.slideTo(0, 0.5, false);
    }
  }

  private handleError(error: any) {
    console.error('error in querying the photos metadata!', error);
    const message = error.error.hasOwnProperty('message') ? error.error.message : error;
    showNotification.next({
      title: 'Error',
      message: `Could not handle the photos metadata. Error: ${message} Please contact the server administration.`,
      class: 'is-error'
    });
  }

  setUsable(photo: any) {
    photo.usable = !photo.usable;
    const updatedPhoto$: Observable<any> | undefined = this.plotsService.postPhotoMetadata([photo]);
    updatedPhoto$.subscribe({
      next: val => console.log('testing photo PUT metadata for usable', val),
      error: error => {
        photo.usable = !photo.usable; // reverse the changed value
        this.handleError(error);
      }
    });

  }

  saveComment(photo: any) {
    console.log('will save comment', photo);
    const updatedPhoto$: Observable<any> | undefined = this.plotsService.postPhotoMetadata([photo]);
    updatedPhoto$.subscribe({
      next: val => console.log('testing photo PUT metadata for comment', val),
      error: this.handleError.bind(this)
    });
  }

  toggleSelect(image: any) {
    this.selectedImage = this.selectedImage === image ? null : image;
    plotImageSelected.next(this.selectedImage);
  }

  emitSlideChange() {
    console.log('will emit slide change');

    if (this.activeTab !== 'satellite' && this.activePhoto) {
      selectedPhotoChange.next({
        geom: this.activePhoto!.location,
        angle: this.activePhoto!.viewdirection,
        detail: this.activePhoto!.detail
      });
    } else {
      selectedPhotoChange.next(null);
    }
  }

  selectTab(tab: 'satellite' | 'photos') {
    this.activeTab = tab;
    if (tab !== 'satellite') {
      this.activePhoto = this.photoData[0];
    }
    this.emitSlideChange();
  }

  hasChildren(obj: any): boolean {
    return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0;
  }

  isArray(obj: any): boolean {
    return obj.hasOwnProperty('length');
  }


  onSlideChange(swiper: Swiper) {
    const currentIndex = swiper.realIndex;
    const currentPhoto = this.photoData[currentIndex];

    this.currentSlideIndex = currentIndex;
    this.activePhoto = currentPhoto;

    selectedPhotoChange.next({
      geom: currentPhoto.location,
      angle: currentPhoto.viewdirection,
      detail: currentPhoto.detail
    });
  }


  togglePreview(status: boolean) {
    this.showImageModal = status;
  }

  rotatePhoto(degrees: number) {
    this.rotated = degrees;
  }

  getClassificationTranslation(field: string) {
    if (field && ((ClassificationLabels as any)[field.replace(/-/g, '_')])) {
      return (ClassificationLabels as any)[field.replace(/-/g, '_')];
    } else {
      return field;
    }
  }

  getResult(results: PhotoClassificationResult[], model: string) {
    return results.find(x => x.method === model);
  }

  getPercentageColor(percentage: number) {
    if (percentage >= 0.75) {
      return 'has-background-success';
    } else if (percentage >= 0.5 && percentage < 0.75) {
      return 'has-background-warning';
    } else {
      return 'has-background-danger';
    }
  }
  getlwtColor(lwt: string, grass: boolean) {
    if (grass && (lwt === 'mown' || lwt === 'grazed')) {
      return 'has-background-success';
    } else if (grass && lwt === 'unmown') {
      return 'has-background-danger';
    } else {
      return '';
    }
  }

}
