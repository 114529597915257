import {Component, ElementRef, OnInit, Input} from '@angular/core';
import {PlotShort, PlotShortAttribute, PlotShortLike} from "../../model/plot";
import {PlotsService} from "../../services/plots.service";
import {firstValueFrom, Observable, map} from "rxjs";
import {UtilService} from "../../services/util.service";
import {PlotListSingleComponent} from "../plot-list-single/plot-list-single.component";
import {CommonModule} from "@angular/common";
import {Router} from "@angular/router";
import {
  gridTemplatesChanged,
  requestRefreshPlot,
  selectedPlotsChange,
  showNotification,
  stateSet
} from '../../events';
import {StorageService} from '../../services/storage.service';
import {GraphqlService} from "../../services/graphql.service";

@Component({
  standalone: true,
  selector: 'app-plots',
  templateUrl: './plots.component.html',
  imports: [
    CommonModule,
    PlotListSingleComponent,
  ],
  styleUrls: ['./plots.component.scss']
})
export class PlotsComponent implements OnInit {
  @Input() userIsCustomer: boolean = false;

  plots: PlotShort[] = [];
  watchlistPlots: PlotShort[] = [];
  selectedPlotIds$: Observable<{plotIds: number[], origin: string}> = selectedPlotsChange;

  plotsCount: { total: number; open: number; edited: number; closed: number } | null = null;

  watchlistCount: { total: number; open: number; edited: number; closed: number } | null = null;

  pageSize: number = 20;

  offset: number = 0;
  offsetWatchlist: number = 0;

  sorting: string = 'sort: {field: "id", direction: ASC}';
  sortingWatchlist: string = 'sort: {field: "id", direction: ASC}';

  extraFilters: string = '';
  extraFiltersWatchlist: string = '';

  statesShown: string = '';
  statesShownWatchlist: string = '';

  activeTab: 'plots' | 'watchlist' = 'plots';

  maxHeight: string = '28vh';

  constructor(
    private plotsService: PlotsService,
    private router: Router,
    private util: UtilService,
    private element: ElementRef,
    private storageService: StorageService,
    private graphql: GraphqlService
  ) {
  }

  async ngOnInit() {
    requestRefreshPlot.subscribe((value: {
      plotId: number;
      operation: 'add' | 'remove' | 'update';
      watchlist?: boolean
    }) => this.refreshPlot(value))

    stateSet.subscribe(() => {
      // selectedPlotsChange$.next([]);
      if (this.userIsCustomer) {
        this.requestCustomerPlots();
      } else {
        this.requestPlots();
        this.requestWatchlistPlots();
      }

    });
    if (this.userIsCustomer) {
      this.requestCustomerPlots();
    } else {
      await this.requestPlots();
      await this.requestWatchlistPlots();
    }

    gridTemplatesChanged.subscribe(templates => {
      const height = this.element.nativeElement.clientHeight;
      this.maxHeight = height + 'px';

      const maxPlotsVisible = (height - 85) / 25;
      const count = this.activeTab === 'plots' ? this.plotsCount?.total : this.watchlistCount?.total;
      if (count && maxPlotsVisible > count) {
        this.onScrolled(this.activeTab === 'watchlist');
      }
    });
  }

  async requestCustomerPlots() {
    const customerFilter = await this.storageService.get('customerFilter') as string;
    if (customerFilter) {
      const  parsedFilters = JSON.parse(customerFilter);

      let filterString = this.util.stringifyGraphQL(parsedFilters);
      if (this.offset === 0) {
        const totalCount = await firstValueFrom(this.graphql.queryField({
          field: 'featuresCount',
          filter: filterString
        }));

        if (totalCount) {
          this.plotsCount = {
            total: totalCount,
            open: 0,
            edited: 0,
            closed: 0
          };
        }
      }
      try {
        this.graphql.queryField({
          field: 'features', attributes: [
            'id',
            'nCodeDeclared {code, codeLabel}',
            'area'
          ],
          filter: filterString
        }).pipe(
          map((response: any) => response
            .filter((plotShortLike: any) => plotShortLike && plotShortLike.id)
            .map((plotShortLike: PlotShortLike) => new PlotShort(plotShortLike)))
        ).subscribe((plots: PlotShort[]) => {
          this.plots = plots;
        })
      } catch (e) {
        const message = !!e ? e.toString() : 'Beim Laden der Schläge ist ein Fehler aufgetreten.';
        showNotification.next({title: 'Error', message, class: 'is-danger'});
      }

    }
  }

  async requestPlots() {
    let filters = this.extraFilters ? this.extraFilters : null;
    if (this.statesShown) {
      if (!filters) {
        filters = this.statesShown;
      } else {
        filters += `, ${this.statesShown}`;
      }
    }
    if (this.offset === 0) {
      this.plotsCount = await firstValueFrom(this.plotsService.getScapiPlotsCount(filters, false));
    }
    if (this.sorting) {
      if (filters) {
        filters += `, ${this.sorting}`;
      } else {
        filters = this.sorting;
      }
    }
    try {
      const plots = await firstValueFrom(this.plotsService.getScapiPlots(this.pageSize, this.offset, filters));
      this.offset === 0 ? this.plots = plots : this.plots = this.plots.concat(plots);
    } catch (e) {
      const message = !!e ? e.toString() : 'Beim Laden der Schläge ist ein Fehler aufgetreten.';
      showNotification.next({title: 'Error', message, class: 'is-danger'});
    }
  }

  async requestWatchlistPlots() {
    let filters = this.extraFiltersWatchlist ? this.extraFiltersWatchlist : null;
    if (this.statesShownWatchlist) {
      if (!filters) {
        filters = this.statesShownWatchlist;
      } else {
        filters += `, ${this.statesShownWatchlist}`;
      }
    }
    if (this.sortingWatchlist) {
      if (filters) {
        filters += `, ${this.sortingWatchlist}`;
      } else {
        filters = this.sortingWatchlist;
      }
    }
    if (this.offsetWatchlist === 0) {
      this.watchlistCount = await firstValueFrom(this.plotsService.getScapiPlotsCount(filters, true));
    }
    try {
      const plots = await firstValueFrom(this.plotsService.getScapiPlots(this.pageSize, this.offsetWatchlist, filters, true));
      this.offsetWatchlist === 0 ? this.watchlistPlots = plots : this.watchlistPlots = this.watchlistPlots.concat(plots);
    } catch (e) {
      const message = !!e ? e.toString() : 'Beim Laden der Schläge auf der Watchlist ist ein Fehler aufgetreten.';
      showNotification.next({title: 'Error', message, class: 'is-danger'});
    }
  }

  async refreshPlot(args: { plotId: number; operation: "add" | "remove" | "update" }) {
    const updatePlot = async (plotId: number) => {
      // get updated plot from server
      const plot = await firstValueFrom(this.plotsService.getScapiPlot(plotId));

      // update plot on plot list
      if (!!plot) {
        const index = this.plots.findIndex(p => p.id === plotId);
        if (index !== -1) {
          // clone, change and reassign the original array to toggle change detection
          const updatedPlots = [...this.plots];
          updatedPlots[index] = plot;
          this.plots = updatedPlots;
        }

        // update plot on watch list
        const watchlistIndex = this.watchlistPlots.findIndex(p => p.id === plotId);
        if (watchlistIndex !== -1) {
          // clone, change and reassign the original array to toggle change detection
          const updatedWatchlist = [...this.watchlistPlots];
          updatedWatchlist[watchlistIndex] = plot;
          this.watchlistPlots = updatedWatchlist;
        }
      }
    }

    const removePlot = async (plotId: number) => {
      const index = this.watchlistPlots.findIndex(p => p.id === plotId);
      if (index !== -1) {
        // clone, change and reassign the original array to toggle change detection
        const updatedWatchlist = [...this.watchlistPlots];
        updatedWatchlist.splice(index, 1);
        this.watchlistPlots = updatedWatchlist;
        // update watchlist count
        let filters = this.extraFiltersWatchlist ? this.extraFiltersWatchlist : null;
        this.watchlistCount = await firstValueFrom(this.plotsService.getScapiPlotsCount(filters, true));
      }
    };

    switch (args.operation) {
      case "update":
        setTimeout(async () => await updatePlot(args.plotId), 1000);
        break;
      case "add":
        // reload whole watchlist since adding new plot would possibly mess up sorting
        setTimeout(async () => {
          this.offsetWatchlist = 0;
          await this.requestWatchlistPlots();
        }, 1000)
        break;
      case "remove":
        setTimeout(async () => await removePlot(args.plotId), 1000);
    }
  }

  setActiveTab(tab: 'plots' | 'watchlist') {
    this.activeTab = tab;
  }

  async setSelectedPlot(plotId: number) {
    //selectedPlotsChange$.next({plotIds: [plotId], origin: 'PlotsComponent'});
    await this.router.navigate([], {
      queryParams: {feature: plotId},
      queryParamsHandling: 'merge'
    });
  }

  async onScrolled(isWatchlist: boolean) {
    if (!this.userIsCustomer) {
      if (isWatchlist) {
        this.offsetWatchlist += 20;
        await this.requestWatchlistPlots();
      } else {
        this.offset += 20;
        await this.requestPlots();
      }
    }
  }

  async setSorting(sorting: { direction: "ASC" | "DESC"; field: PlotShortAttribute }, isWatchlist: boolean) {
    let sortingString = `sort: [${this.util.stringifyGraphQL(sorting).replace(/direction:"(ASC|DESC)"/, 'direction:$1')}]`;
    if (sorting.field !== 'id') {
      const index = sortingString.lastIndexOf(']');
      sortingString = this.util.insertString(sortingString, ', {field: "id", direction: ASC}', index);
    }
    if (isWatchlist) {
      this.offsetWatchlist = 0;
      this.sortingWatchlist = sortingString;
      await this.requestWatchlistPlots();
    } else {
      this.offset = 0;
      this.sorting = sortingString;
      await this.requestPlots();
    }
  }

  async setFilters(filters: {
    field: PlotShortAttribute;
    operation: "<" | ">" | "=";
    value: string
  }[], isWatchlist: boolean) {
    let filtersString = '';
    filters.forEach(filter => {
      switch (filter.field) {
        case 'id':
          filtersString += `, id: ${filter.value}`;
          break;
        case 'area':
          filtersString += `, area: {${filter.operation === '<' ? '_lt' : '_gt'}: ${filter.value}}`
          break;
        case 'eftasZone':
          filtersString += `, eftasZones: ["${filter.value}"]`;
          break;
        case 'nCodeDeclared':
          filtersString += `, ncode: {code: ${filter.value}}`;
          break;
      }
    });
    if (filtersString !== '') {
      filtersString = filtersString.slice(2);
    }
    if (isWatchlist) {
      this.offsetWatchlist = 0;
      this.extraFiltersWatchlist = filtersString;
      await this.requestWatchlistPlots();
    } else {
      this.offset = 0;
      this.extraFilters = filtersString;
      await this.requestPlots();
    }
  }

  async setStatesShown(statesShown: 'open' | 'edited' | 'closed' | 'all', isWatchlist: boolean) {
    let statesShownString = '';
    switch (statesShown) {
      case "open":
        statesShownString = 'assessmentScapi: {exists: false}';
        break;
      case "edited":
        statesShownString = 'assessmentScapi: {index: -1, finished: false}';
        break;
      case "closed":
        statesShownString = 'assessmentScapi: {index: -1, finished: true}';
        break;
    }
    if (isWatchlist) {
      this.offsetWatchlist = 0;
      this.statesShownWatchlist = statesShownString;
      await this.requestWatchlistPlots();
    } else {
      this.offset = 0;
      this.statesShown = statesShownString;
      await this.requestPlots();
    }
  }
}
