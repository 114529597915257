<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless" >
  <ul>
    <li class="is-active">
      <a><span>NDVI Plot</span></a>
    </li>
  </ul>
</div>

<ng-container *ngIf="data.length === 0">
  <div class="content-placeholder" i18n>No plot selected.</div>
</ng-container>

<canvas id="chart" [style.max-height]="'38vh'" [ngClass]="{'invisible': data.length === 0}" #canvas></canvas>



