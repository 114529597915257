<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless">
  <ul>
    <li [ngClass]="{'is-active': activeTab === 'satellite'}">
      <a (click)="selectTab('satellite')">
        <span>Satellite</span>
      </a>
    </li>
    <li [ngClass]="{'is-active': activeTab === 'photos'}">
        <a (click)="selectTab('photos')"><span i18n>Photos</span></a> 
    </li>
  </ul>
</div>

<div class="gallery" *ngIf="activeTab === 'satellite'">
  <img *ngFor="let image of images" src="https://bulma.io/assets/images/placeholders/480x320.png" class="gallery-item"
    [ngClass]="{'selected': selectedImage === image}" (click)="toggleSelect(image)">
</div>

<div *ngIf="activeTab === 'photos'" class="gallery sfb-content">
  <div *ngIf="photoData && photoData.length > 0; else placeholder">
    <ng-template
      *ngTemplateOutlet="photoTemplate; context: {photo : activePhoto, photos: photoData} ">
    </ng-template>
  </div>

  <ng-template #placeholder>
    <div class="content-placeholder"><span i18n>No photo data available.</span></div>
  </ng-template>
</div>

<ng-template #photoTemplate let-photos="photos" let-photo="photo">
  <div class="gallery">
    <div class="gallery-items">
      <div class="leftcol" #leftcol>

        <!--
        <swiper class="swiper" [keyboard]="true" [pagination]="true" [scrollbar]="true" [zoom]="true" [navigation]="true"
          *ngIf="photos && photos.length > 0" (swiper)="onSwiper($event)"
          (slideChangeTransitionEnd)="onSlideChange(type, $event)">
          <ng-template swiperSlide *ngFor="let slide of photos; let i = index">
            <img src="{{ slide.imageId | authImage: true | async }}"
              [ngClass]="{'rotated-clockwise': rotated === 90, 'rotated-counter-clockwise': rotated === 270, 'flipped': rotated === 180}"
              (click)="togglePreview(type, true)">
          </ng-template>
        </swiper>
        -->

        <swiper-container appSwiper class="swiper" *ngIf="photos && photos.length > 0" [config]="swiperOptions" (slideChange)="onSlideChange($event)">
          <swiper-slide *ngFor="let slide of photos; let i = index">
            <img src="{{ slide.imageId | authImage: true | async }}"
                [ngClass]="{'rotated-clockwise': rotated === 90, 'rotated-counter-clockwise': rotated === 270, 'flipped': rotated === 180}"
                (dblclick)="togglePreview(true)">
          </swiper-slide>
        </swiper-container>

        <div class="buttons has-addons is-centered no-bottom-margin">
          <button class="button is-light is-small" (click)="rotatePhoto(90)">
              <strong i18n>Rotate</strong>
              <span class="icon is-small">
                  <i-feather name="rotate-cw" class="icon-smaller"></i-feather>
              </span>
          </button>

          <button class="button is-light is-small" (click)="rotatePhoto(180)">
              <strong i18n>Flip</strong>
              <span class="icon is-small">
                  <i-feather name="refresh-cw" class="icon-smaller"></i-feather>
              </span>
          </button>

          <button class="button is-light is-small" (click)="rotatePhoto(270)">
              <strong i18n>Rotate</strong>
              <span class="icon is-small">
                  <i-feather name="rotate-ccw" class="icon-smaller"></i-feather>
              </span>
          </button>

          <button class="button is-light is-small" (click)="rotatePhoto(0)">
              <strong i18n>Reset</strong>
              <span class="icon is-small">
                  <i-feather name="stop-circle" class="icon-smaller"></i-feather>
              </span>
          </button>

      </div>
        <div class="buttons has-addons is-centered">
          <button *ngIf="photo.usable; else notUsable" class="button is-success is-small" (click)="setUsable(photo)">
            <span class="icon is-small">
              <i-feather name="check" class="icon-smaller"></i-feather>
            </span>
            <strong i18n>Will be delivered</strong>
          </button>
          <ng-template #notUsable>
            <button class="button is-danger is-small" (click)="setUsable(photo)">
              <span class="icon is-small">
                <i-feather name="x" class="icon-smaller"></i-feather>
              </span>
              <strong i18n>Will not be delivered</strong>
            </button>
          </ng-template>
        </div>
      </div>

      <div class="rightcol" #rightcol>
        <div class="columns">
          <div class="column has-text-weight-semibold">
            <span class="tag is-white is-medium" i18n>Photo Details</span>
          </div>
          <div class="column has-text-weight-semibold has-text-right">
            <span class="tag is-info is-medium" *ngIf="photo.source === 'SFB'; else faniTag">sFB</span>
            <ng-template #faniTag> 
              <span class="tag is-link is-medium">FANi</span>
            </ng-template>
          </div>
        </div>
        <table *ngIf="photo" class="table is-fullwidth table-compact overflow-breaking">
          <tbody>
            <tr>
              <th colspan="1"><span i18n>Plot ID</span></th>
              <td class="has-text-right">{{photo.schlag}}</td>
            </tr>
            <tr>
              <th colspan="1"><span i18n>Source</span></th>
              <td class="has-text-right">{{photo.source}}</td>
            </tr>
            <tr>
              <th colspan="1"><span i18n>Operator</span></th>
              <td class="has-text-right" *ngIf="photo.source === 'SFB'; else faniOperator">{{photo.operator}}</td>
              <ng-template #faniOperator> 
                <td class="has-text-right" i18n>Applicant</td>
              </ng-template>
            </tr>
            <tr>
              <th colspan="1"><span i18n>Detail</span></th>
              <td class="has-text-right" [innerHTML]="photo.detail | boolean"></td>
            </tr>
            <tr>
              <th colspan="1"><span i18n>Record date</span></th>
              <td class="has-text-right">{{photo.recordDate | date:'short':'+0200':'de'}}</td>
            </tr>
            <tr>
              <th colspan="1"><span i18n>Upload date</span></th>
              <td class="has-text-right">{{photo.uploadDate | date:'short':'+0200':'de'}}</td>
            </tr>
          </tbody>
          </table>

          <div *ngIf="!userIsCustomer; else onlyFI">
            <table *ngIf="(getResult(photo.results, 'CROPANALYZER_MONOCULTURES') || getResult(photo.results, 'CROPANALYZER_24')
                        || getResult(photo.results, 'CROPANALYZER_MIXEDCROPS') || getResult(photo.results, 'CROPANALYZER_FALLOW')
                        || getResult(photo.results, 'CROPANALYZER_GRASS') || getResult(photo.results, 'FLORAINCOGNITA'))"
              class="table is-bordered bottom-border is-fullwidth table-compact overflow-breaking">
              <tbody>
                <tr *ngIf="getResult(photo.results, 'CROPANALYZER_MONOCULTURES') as result;">
                  <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: false}"></ng-template>
                </tr>
                <tr *ngIf="getResult(photo.results, 'CROPANALYZER_24') as result;">
                  <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: false}"></ng-template>
                </tr>
                <tr *ngIf="getResult(photo.results, 'CROPANALYZER_MIXEDCROPS') as result;">
                  <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: false}"></ng-template>
                </tr>
                <tr class="blank_row" *ngIf="getResult(photo.results, 'CROPANALYZER_FALLOW') &&
                  (getResult(photo.results, 'CROPANALYZER_MONOCULTURES') || getResult(photo.results, 'CROPANALYZER_24')
                        || getResult(photo.results, 'CROPANALYZER_MIXEDCROPS'))">
                    <td colspan="5"></td>
                </tr>
                <tr *ngIf="getResult(photo.results, 'CROPANALYZER_FALLOW') as result;">
                  <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: false}"></ng-template>
                </tr>
                <tr class="blank_row" *ngIf="(getResult(photo.results, 'CROPANALYZER_FALLOW') ||
                  (getResult(photo.results, 'CROPANALYZER_MONOCULTURES') || getResult(photo.results, 'CROPANALYZER_24')
                        || getResult(photo.results, 'CROPANALYZER_MIXEDCROPS'))) && getResult(photo.results, 'CROPANALYZER_GRASS')">
                      <td colspan="5"></td>
                </tr>
                <tr  *ngIf="getResult(photo.results, 'CROPANALYZER_GRASS') as result;">
                  <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: true}"></ng-template>
                </tr>
                <tr class="blank_row" *ngIf="(getResult(photo.results, 'CROPANALYZER_FALLOW') ||
                  (getResult(photo.results, 'CROPANALYZER_MONOCULTURES') || getResult(photo.results, 'CROPANALYZER_24')
                        || getResult(photo.results, 'CROPANALYZER_MIXEDCROPS')) || getResult(photo.results, 'CROPANALYZER_GRASS')) && getResult(photo.results, 'FLORAINCOGNITA')">
                      <td colspan="5"></td>
                </tr>
                <tr *ngIf="getResult(photo.results, 'FLORAINCOGNITA') as result;">
                  <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: false}"></ng-template>
                </tr>
              </tbody>
            </table>
          </div>

      <ng-template #onlyFI>
        <table *ngIf="getResult(photo.results, 'FLORAINCOGNITA') as result;"
          class="table is-bordered bottom-border is-fullwidth table-compact overflow-breaking">
          <tbody>
            <tr>
              <ng-template *ngTemplateOutlet="resultTemplate; context: {result : result, grass: false}"></ng-template>
            </tr>
          </tbody>
        </table>
      </ng-template>

      <ng-template #resultTemplate let-result="result" let-grass="grass">
          <th colspan="2">
            {{ getClassificationTranslation(result.method) }}
          </th>
          <td *ngIf="result.predictClass; else noResult" class="has-text-centered"
          [ngClass]="getlwtColor(result.predictClass, grass)" >{{ getClassificationTranslation(result.predictClass)}}</td>
          <td *ngIf="result.predictClass" class="has-text-right fixed-width"
            [ngClass]="getPercentageColor(result.perc)">{{result.perc * 100 | number :'1.0-2'}}%</td>
          <ng-template #noResult>
            <td class="has-text-centered"><i i18n>Processing...</i></td>
            <td class="has-text-right fixed-width"></td>
          </ng-template>
      </ng-template>

      </div>
    </div>
  </div>
</ng-template>

<app-image-modal *ngIf="showImageModal" [photoData]="photoData" [slide]="currentSlideIndex"
  (onClose)="togglePreview(false)"></app-image-modal>

