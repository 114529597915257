import {Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';

import * as d3 from "d3";

import {PlotsService} from "../../services/plots.service";
import {PlotEvent, PlotMonitors} from "../../model/plot";
import {firstValueFrom} from "rxjs";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import {dashboardResized, eventsUpdate, showNotification} from '../../events';
import {CommonModule} from "@angular/common";
import {Monitor} from "../../model/assessment";
import {selectedPlotsChange} from '../../events';
import {UtilService} from '../../services/util.service';
import {ChartService} from '../../services/chart.service';
import {NdviDataSorted, Coherence} from '../../model/plot';

Chart.register(annotationPlugin);

@Component({
  standalone: true,
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  imports: [
    CommonModule
  ],
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Input() userIsCustomer: boolean = false;

  @ViewChild('canvas') canvas: ElementRef | undefined;

  chart: Chart | undefined;
  sentinelNDVI: { date: Date; median: number }[] = [];
  planetNDVI: { date: Date; median: number }[] = [];
  lockout: Monitor | undefined;
  plotMonitors: PlotMonitors | undefined;

  orbits: string[] = [];
  coherences: Coherence[] = [];

  data: NdviDataSorted[] = [];

  events: PlotEvent[] = [];

  constructor(private plotsService: PlotsService,
    private util: UtilService,
    private chartService: ChartService
  ) {
  }

  ngOnInit() {
    dashboardResized
      .subscribe(() => {
        try {
          console.log('on dashboard resize');
          this.chart?.resize();
        } catch (e) {
          // nobody cares
        }
      });
    selectedPlotsChange
      .subscribe(async data => {
        console.log('on selected plot change', data.plotIds);
        if (data.plotIds.length >= 1) {
          // TODO if userIsCustomer - static image
          await this.getData(data.plotIds[0]);
          await this.drawChart();
        } else {
          await this.clearChart();
        }
      });
    eventsUpdate.subscribe(async value => {
      console.log('on events update');
      await this.getData(value.plotIds[0]);
      await this.drawChart();
    });
  }




  private async getData(plotId: number) {
    try {
      await this.clearChart();
      this.data = [];
      this.planetNDVI = [];
      this.sentinelNDVI = [];
      this.orbits = [];
      this.coherences = [];

      // this.plotsService.getChartData(plotId, 'ndvi')
      //   .pipe(map(chartData => chartData.map(entry => {
      //     const ndvis: {
      //       planet: { date: Date, median: number }[];
      //       sentinel: { date: Date, median: number }[]
      //     } = {planet: [], sentinel: []};
      //     if (entry.source === 'PLANET') {
      //       ndvis.planet.push({date: new Date(entry.date), median: entry.median});
      //     } else {
      //       ndvis.sentinel.push({date: new Date(entry.date), median: entry.median});
      //     }
      //     return ndvis;
      //   }))).subscribe(data => console.log(data));

      // get the data
      const allNDVI = await firstValueFrom(this.plotsService.getNdviData(plotId));
      if (allNDVI) allNDVI.forEach(entry => {
        if (entry.source === 'PLANET') {
          this.planetNDVI.push({ date: new Date(entry.date), median: entry.median });
        } else {
          this.sentinelNDVI.push({ date: new Date(entry.date), median: entry.median })
        }
      });

      const allCoherence =  await firstValueFrom(this.plotsService.getCoherenceData(plotId))
      let vhCoherence: any[] = [];
      if (allCoherence) {
        vhCoherence = allCoherence.filter(entry => entry.polarisation === 'VH');        
      
        vhCoherence.forEach(entry => {
          let coherenceCategory = this.coherences.find(value => value.orbit === entry.orbit && value.polarisation === entry.polarisation);
          if (!coherenceCategory) {
            coherenceCategory = {orbit: entry.orbit, polarisation: entry.polarisation as 'VH' | 'VV', values: []};
            this.coherences.push(coherenceCategory);
          }
          coherenceCategory.values.push({date: new Date(entry.date), median: entry.median});
        });
      }

      this.orbits = Array.from(new Set(this.coherences.map(coherenceCategory => coherenceCategory.orbit)));

      const currentYear = this.sentinelNDVI[0]?.date ? this.sentinelNDVI[0].date.getFullYear() : (
        this.planetNDVI[0]?.date ? this.planetNDVI[0].date.getFullYear() : vhCoherence[0]?.date ? new Date(vhCoherence[0].date).getFullYear() : 2024);

      // interpolate separately within the ranges for which there is data
      const sentinelInterpolated = this.sentinelNDVI.length > 0 ? await this.util.interpolateDates(this.sentinelNDVI) : [];
      const planetInterpolated = this.planetNDVI.length > 0 ? await this.util.interpolateDates(this.planetNDVI) : []
      const coherencesInterpolated: {orbit: string, polarisation: 'VH' | 'VV', values: {date: Date, median: number}[]}[] = [];
      for (const coherenceCategory of this.coherences) {
        const copyOfCategory = Object.assign({}, coherenceCategory);
        copyOfCategory.values = await this.util.interpolateDates(coherenceCategory.values);
        coherencesInterpolated.push(copyOfCategory);
      }

      // TODO find the year from the data
      const allDates = d3.timeDays(
        new Date(currentYear - 1 +'-12-31'),
        new Date(currentYear + '-11-31')
      );

      // combine the data
      allDates.forEach((date) => {
        const sentinel = sentinelInterpolated.find(item => item.date.getTime() === date.getTime());
        const planet = planetInterpolated.find(item => item.date.getTime() === date.getTime());
        const coherences: {orbit: string, polarisation: "VH" | "VV", median: number}[] = [];
        coherencesInterpolated.forEach(coherenceCategory => {
          coherenceCategory.values.forEach(value => {
            if (value.date.getTime() === date.getTime()) {
              coherences.push({orbit: coherenceCategory.orbit, polarisation: coherenceCategory.polarisation, median: value.median});
            }
          });
        });

        this.data.push({
          date: date,
          ndviSentinel: sentinel ? sentinel.median : null,
          coherences,
          ndviPlanet: planet ? planet.median : null
        });
      });

      this.lockout = await firstValueFrom(this.plotsService.getMonitor('SPERR_BRA'));
      this.plotMonitors = await firstValueFrom(this.plotsService.getPlotMonitors(plotId));
      const isLockoutShown = this.findSperrBra();
      if (isLockoutShown) {
        this.lockout.dateStartParsed = this.getDateParsed(this.lockout.dateStart, currentYear);
        this.lockout.dateEndParsed = this.getDateParsed(this.lockout.dateEnd, currentYear);
      }
      console.log('lockout start date parsed', this.lockout.dateStartParsed);

      this.events = await firstValueFrom(this.plotsService.getPlotEvents(plotId));

    } catch (e) {
      console.error(e);
      // showNotification.next({ title: 'Error', message: `1 No plot found for ID ${plotId}.` + e });
    }
  }

  private getDateParsed(date: string, currentYear: number) {
    const [day, month] = date.split('.');
    return new Date(currentYear, Number(month) - 1, Number(day));
  }


  private async drawChart() {
    // const getDaysArray = (start: any, end: any) => {
    //   const arr = []
    //   for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    //     arr.push(roundDownDate(dt));
    //   }
    //   return arr;
    // }

    // const roundDownDate = (date: any) => {
    //   if (typeof date !== "object" || !date.getUTCMilliseconds) {
    //     throw Error("Arg must be a Date object.");
    //   }
    //   const offsetMs = date.getTimezoneOffset() * 60 * 1000,
    //     oneDayMs = 24 * 60 * 60 * 1000;
    //   return Math.floor((date.getTime() - offsetMs) / oneDayMs) * oneDayMs + offsetMs;
    // }

    await this.clearChart();
    if (this.data.length > 0 && this.canvas) {

      const chartData: { labels: any[], datasets: any[], allAnnotations: any[] } = await this.chartService.createChart(this.data, this.sentinelNDVI,
        this.planetNDVI, this.orbits, this.coherences, this.lockout, this.events);

      const labels = chartData.labels;
      const datasets = chartData.datasets;
      const allAnnotations = chartData.allAnnotations;

      const me = this;

      for (var name in allAnnotations) {
        if (name.indexOf('Sentinel NDVI') === 0) {
          allAnnotations[name].display = function() { 
            const dataset = me.chart?.legend?.legendItems?.find((obj: any) => {
              return obj.text === 'Sentinel NDVI';
            });
            return dataset && !dataset.hidden;
            }
        } else if (name.indexOf('Planet NDVI') === 0) {
          allAnnotations[name].display = function() { 
            const dataset = me.chart?.legend?.legendItems?.find((obj: any) => {
              return obj.text === 'Planet NDVI';
            });
            return dataset && !dataset.hidden;
            }
        } else if (name.indexOf('COH') === 0) {
          allAnnotations[name].display = function() { 
            const dataset = me.chart?.legend?.legendItems?.find((obj: any) => {
              return obj.text === 'Coherence VH';
            });
            return dataset && !dataset.hidden;
            }
        }
      }

      this.chart = new Chart(this.canvas.nativeElement, {
        type: 'line',
        data: {
          labels,
          datasets
        },
        options: {
          animation: false,
          scales: {
            y: {
              min: 0,
              max: 1.0
            },
            x: {
              min: labels[0],
              max: labels[labels.length - 1],
              type: 'time',
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'D.MM.YY'
                }
              },
              grid: {
                color: "#bababa"
              }
            }
          },
          plugins: {
            annotation: {
              annotations: allAnnotations
            },
            legend: {
              labels: {
                // TODO make it more obvious that they're clickable
              },
              onClick: this.legendClickHandler
            }
          },
        }
      });
      this.chart.update();
    }
  }


  private legendClickHandler(e: any, legendItem: any, legend: any) {
    // the default stuff
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
    // to update the annotations
    e.chart.update();
  }

  private async clearChart() {
    this.chart?.destroy();
  }

  findSperrBra() {
    if (this.plotMonitors && this.plotMonitors.finalResults.length > 0) {
      return this.plotMonitors.finalResults.find(item => item.monitor === 'SPERR_BRA');
    } else{
      return false;
    }
  }

}
