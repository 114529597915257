import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router, RouterOutlet, RoutesRecognized} from '@angular/router';
import {environment} from '../environments/environment';
import {dashboardVersion} from './constants';
import {StatesService} from './services/states.service';
import {UserService} from './services/user.service';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FeatherModule} from 'angular-feather';
import {FormsModule} from '@angular/forms';

// import function to register Swiper custom elements
import {register} from 'swiper/element/bundle';
import {requestResetDashboardLayout, selectedPlotsChange, showNotification, userInactive, userTimeLeft} from './events';
import {IdleService} from './services/idle.service';
import {TimePipe} from './pipes/time.pipe';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FeatherModule,
    NgOptimizedImage,
    FormsModule,
    TimePipe
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {

  /**
   * The application's navbar element
   */
  @ViewChild('navbar') navbar: ElementRef | undefined;

  /**
   * The application's title
   */
  title = 'AMS-Dashboard';

  /**
   * Logged in user's username
   */
  username: string | undefined;

  /**
   * Currently active menu item in the navbar
   */
  activeMenuItem: 'scapi' | 'administration' | 'search' | 'customerDashboard' = 'scapi';

  /**
   * URL to the keycloak server
   */
  keyCloakUrl = environment.keycloak_url + '/admin/ams/console';

  userRoles: string[] = [];

  /**
   * Whether the hamburger menu (shown in portrait mode) is currently open
   */
  isHamburgerOpen: boolean = false;

  timeLeft: number = -1;

  allNotifications: any[] = [];

  // Bindings for template use
  dashboardVersion = dashboardVersion;
  window = window;

  constructor(
    public states: StatesService,
    public user: UserService,
    private router: Router,
    private idleService: IdleService
  ) {
  }

  async ngOnInit() {
    // subscription to router events to set the active menu item accordingly
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
          console.log('matching url', data.url);
          if ((data.url.match(/\/customerDashboard/) as RegExpMatchArray | null) !== null) {
            this.activeMenuItem = 'customerDashboard';
          } else if ((data.url.match(/\/search/) as RegExpMatchArray | null) !== null) {
            this.activeMenuItem = 'search';
          } else if ((data.url.match(/\/administration/) as RegExpMatchArray | null) !== null) {
            this.activeMenuItem = 'administration';
          } else {
            this.activeMenuItem = 'scapi';
          }
        }
    })
    // wait for user service to process auth token...
    await this.user.processUserToken();
    // ...before setting username
    this.username = this.user.getUsername();
    this.userRoles = this.user.getUserRoles();

    if (this.userRoles.some(str => str.includes('customer'))) {
      const customerRole = this.userRoles.find(str => str.includes('customer'));
      console.log('found customer role', customerRole);
      const customerCountry = customerRole!.split('_')[1].toUpperCase();
      this.states.setActiveState(customerCountry);
    }

    showNotification.subscribe(async data => {
      console.log('Events: on allNotifications change', data);
      this.allNotifications.push(data);
    });

    userInactive.subscribe(() => this.user.logout());
    userTimeLeft.subscribe(timeLeft => this.timeLeft = timeLeft);
  }

  ngAfterViewInit() {
    // register Swiper custom elements
    register();
  }

  /**
   * Resets the dashboard's layout to default values
   */
  resetDashboardLayout() {
    requestResetDashboardLayout.next();
  }

  /**
   * Closes any active notification
   */
  closeNotification() {
    this.allNotifications = [];
    // showNotification.complete();
  }

  /**
   * Sets the currently active menu item and triggers navigation if selected menu item does not correspond to current
   * route
   *
   * @param menuItem - The menu item to set as active
   */
  async setActiveMenuItem(menuItem: 'scapi' | 'administration' | 'search' | 'customerDashboard') {
    if (this.activeMenuItem !== menuItem) {
      await this.router.navigate([menuItem], {queryParamsHandling: 'preserve'});
      this.activeMenuItem = menuItem;
    }
  }

  userIsCustomer() {
    return this.userRoles.some(str => str.includes('customer'));
  }


  userIsSCapi() {
    return this.userRoles.some(str => str.includes('scapi'));
  }

  /**
   * Resets currently selected features. Triggered on state change.
   */
  resetFeatures() {
    selectedPlotsChange.next({plotIds: [], origin: 'AppComponent'});
  }

  /**
   * Opens/closes the hamburger menu
   */
  toggleHamburgerMenu() {
    this.isHamburgerOpen = !this.isHamburgerOpen;
  }
}
