<div class="tabs dashboard-component-tabs is-boxed is-small is-marginless">
  <ul>
    <li [ngClass]="{'is-active': activeTab === 'editor'}">
      <a (click)="setActiveTab('editor')"><span i18n>Editor</span></a>
    </li>
    <li [ngClass]="{'is-active': activeTab === 'assessments'}">
      <a (click)="setActiveTab('assessments')"><span i18n>Assessments</span></a>
    </li>
  </ul>
</div>

<div *ngIf="plotIds.length >= 1; else placeholder" class="form">
  <ng-container *ngTemplateOutlet="activeTab === 'editor' ? editorTab : assessmentsTab"></ng-container>
</div>

<ng-template #editorTab>

  <ng-container *ngIf="activeAssessment?.hasOwnProperty('revision')">
    <div *ngIf="activeAssessment?.hasOwnProperty('finished') && activeAssessment?.finished === false" class="notification is-warning">
      <ng-container i18n>Unfinished Revision</ng-container> {{getRevision()}}
    </div>
    <div *ngIf="activeAssessment?.hasOwnProperty('finished') && activeAssessment?.finished === true" class="notification is-info">
      <ng-container i18n>Finished Revision</ng-container> {{getRevision()}}
    </div>
  </ng-container>

  <!--<div class="form-part">
    <h5 class="form-title">Location check</h5>

    <label class="checkbox">
      <input type="checkbox">
      FOI not uniquely identifiable
    </label>
  </div>-->
  <div class="padding-10">
    <div class="form-part">
      <h5 class="form-title" [ngClass]="{'grey-text': monitorFinished('NUTZ') || greenHouse.gewaechshaus_folientunnel}" i18n>Cultivation check</h5>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': monitorFinished('NUTZ')}">
          <input type="radio" name="cultivation" [(ngModel)]="cultivation" value="confirmed"
                 (change)="this.setState('dirty'); this.setNCode('confirmed')" [disabled]="monitorFinished('NUTZ') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Confirmed</span> &nbsp; <span *ngIf="!!activeAssessment && activeAssessment.finished && activeAssessment.nCodeDeclared"> ({{activeAssessment.nCodeDeclared}})</span>
        </label>
      </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': monitorFinished('NUTZ')}">
          <input type="radio" name="cultivation" [(ngModel)]="cultivation" value="other"
                 (change)="this.setState('dirty'); this.setNCode('other')" [disabled]="monitorFinished('NUTZ') ||  (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Other</span>
        </label>
        <div class="flex-spacer"></div>
        <select style="width: 170px; margin-left: 1em; font-size: 12px;" [(ngModel)]="nCodeMeasured"
          [disabled]="monitorFinished('NUTZ') || (!!activeAssessment && activeAssessment.finished || cultivation !== 'other') || greenHouse.gewaechshaus_folientunnel"
          (change)="this.setState('dirty')">
          <option disabled selected [ngValue]="null"></option>
          <ng-container>
            <option *ngFor="let code of nCodes" [ngValue]="code.code">{{code.code}}
              : {{code.codeLabel}}</option>
          </ng-container>
        </select>
      </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': monitorFinished('NUTZ')}">
          <input type="radio" name="cultivation" [(ngModel)]="cultivation" value="nutzung_zusaetzlich"
                 (change)="this.setState('dirty'); this.setNCode('nutzung_zusaetzlich')" [disabled]="monitorFinished('NUTZ') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Multiple uses</span>
        </label>
      </div>
    </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': monitorFinished('NUTZ')}">
          <input type="radio" name="cultivation" [(ngModel)]="cultivation" value="nicht_bewertbar"
                 (change)="this.setState('dirty'); this.setNCode('nicht_bewertbar')" [disabled]="monitorFinished('NUTZ') || (!!activeAssessment && activeAssessment.finished)  || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Use not assessable</span>
        </label>
      </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': monitorFinished('NUTZ')}">
          <input type="radio" name="cultivation" [(ngModel)]="cultivation" value="nutzung_unklar"
                 (change)="this.setState('dirty'); this.setNCode('nutzung_unklar')" [disabled]="monitorFinished('NUTZ') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Unclear</span>
        </label>
      </div>


    <div class="form-part">
      <h5 class="form-title" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}" i18n>Minimum activity/Agricultural use</h5>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="radio" name="activity"  value="solvable" [(ngModel)]="activitySolvable" (change)="this.setState('dirty')" [disabled]="true">
          <span i18n>Solvable</span>
        </label>
      </div>

      <div class="form-row margin-left-15">
        <label class="checkbox" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="checkbox" [(ngModel)]="activities.taetigkeit_mahd_mulchen"
          [disabled]="(monitorFinished('BRA') && monitorFinished('DGL')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel" (ngModelChange)="onActivityChange('taetigkeit_mahd_mulchen', $event)">
          <span i18n>Mowing/Mulching</span>
        </label>
      </div>

      <div class="form-row margin-left-15">
        <label class="checkbox" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="checkbox" [(ngModel)]="activities.taetigkeit_beweidung"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel" (ngModelChange)="onActivityChange('taetigkeit_beweidung', $event)">
          <span i18n>Grazing</span>
        </label>
      </div>

      <div class="form-row margin-left-15">
        <label class="checkbox" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="checkbox" [(ngModel)]="activities.taetigkeit_umbruch"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel" (ngModelChange)="onActivityChange('taetigkeit_umbruch', $event)">
          <span i18n>Ploughing</span>
        </label>
      </div>

      <div class="form-row margin-left-15">
        <label class="checkbox" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="checkbox" [(ngModel)]="activities.taetigkeit_aussaat"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel" (ngModelChange)="onActivityChange('taetigkeit_aussaat', $event)">
          <span i18n>Sowing/Reseeding</span>
        </label>
      </div>

      <div class="form-row margin-left-15">
        <label class="checkbox" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="checkbox" [(ngModel)]="activities.taetigkeit_andere"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel" (ngModelChange)="onActivityChange('taetigkeit_andere', $event)">
          <span i18n>OK, but not unambiguous</span>
        </label>
      </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="radio" name="activity" value="not_solvable_in_scapi" [(ngModel)]="activitySolvable" (ngModelChange)="onActivitySolvableChange($event)"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Not solvable in sCAPI</span>
        </label>
      </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="radio" name="activity" value="permanently_unused" [(ngModel)]="activitySolvable" (ngModelChange)="onActivitySolvableChange($event)"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished  || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Permanently unused/bushy</span>
        </label>
      </div>

      <div class="form-row">
        <label class="radio" [ngClass]="{'grey-text': (monitorFinished('BRA') && monitorFinished('DGL'))}">
          <input type="radio" name="activity" value="not_solvable_yet" [(ngModel)]="activitySolvable" (ngModelChange)="onActivitySolvableChange($event)"
          [disabled]="(monitorFinished('DGL') && monitorFinished('BRA')) || !!activeAssessment && activeAssessment.finished || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Not solvable yet</span>
        </label>
      </div>


      <!--<div class="form-row">
        <label class="radio">
          <input type="radio" name="activity">
          Not solvable, SFB
        </label>
      </div>-->
    </div>

    <div class="form-part">
      <h5 class="form-title" [ngClass]="{'grey-text': violationMonitorFinished('SPERR_BRA')}" i18n>Minimum activity during lockout period</h5>
      <div class="form-row">
        <label class="checkbox" [ngClass]="{'grey-text': violationMonitorFinished('SPERR_BRA')}">
          <input type="checkbox" [ngModel]="lockoutActivity.taetigkeit_sperrzeitraum_bestaetigt"
                 [value]="lockoutActivity.taetigkeit_sperrzeitraum_bestaetigt"
                 (ngModelChange)="onLockoutActivityChange('confirmed', $event)"
                 [disabled]="violationMonitorFinished('SPERR_BRA') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Yes</span>
        </label>
        &nbsp;&nbsp;
        <label class="checkbox" [ngClass]="{'grey-text': violationMonitorFinished('SPERR_BRA')}">
          <input type="checkbox" [ngModel]="lockoutActivity.taetigkeit_sperrzeitraum_revidiert"
                 [value]="lockoutActivity.taetigkeit_sperrzeitraum_revidiert"
                 (ngModelChange)="onLockoutActivityChange('revised', $event)"
                 [disabled]="violationMonitorFinished('SPERR_BRA') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>No</span>
        </label>
      </div>
    </div>

    <div class="form-part">
      <h5 class="form-title" [ngClass]="{'grey-text': violationMonitorFinished('NBF'), 'has-text-danger': nbfCheck}" i18n>NBF</h5>
      <div class="form-row">
        <label class="checkbox" [ngClass]="{'grey-text': violationMonitorFinished('NBF')}">
          <input type="checkbox" [ngModel]="nbf.nbf" [value]="nbf.nbf"
                 (ngModelChange)="onNbfChange('confirmed', $event)"
                 [disabled]="violationMonitorFinished('NBF') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>Yes</span>
        </label>
        &nbsp;&nbsp;
        <label class="checkbox" [ngClass]="{'grey-text': violationMonitorFinished('NBF')}">
          <input type="checkbox" [ngModel]="nbf.nbf_revidiert" [value]="nbf.nbf_revidiert"
                 (ngModelChange)="onNbfChange('revised', $event)"
                 [disabled]="violationMonitorFinished('NBF') || (!!activeAssessment && activeAssessment.finished) || greenHouse.gewaechshaus_folientunnel">
          <span i18n>No</span>
        </label>
      </div>
    </div>
    <div class="small-spacer"></div>
    <div class="form-part">
      <div class="form-row">
        <label class="checkbox" [ngClass]="{'grey-text': (monitorFinished('NUTZ'))}">
          <input type="checkbox" [(ngModel)]="greenHouse.gewaechshaus_folientunnel" [disabled]="(monitorFinished('NUTZ')) || !!activeAssessment && activeAssessment.finished" (ngModelChange)="onGreenhouseChange($event)">
          <span style="font-weight: bold;" i18n>Greenhouse / Polytunnel</span>
        </label>
      </div>
    </div>
    <div class="small-spacer"></div>
    <div class="form-part">
      <h5 class="form-title" i18n>Comments</h5>
      <div class="control">
      <textarea class="textarea is-small" rows="3" [ngModel]="comment" i18n-placeholder="Custom comment"
                placeholder="Custom comment" (ngModelChange)="this.setState('dirty'); this.setComment($event)" [disabled]="!!activeAssessment && activeAssessment.finished"></textarea>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assessmentsTab>
  <table class="table is-narrow is-hoverable is-fullwidth table-compact">
    <thead>
    <tr>
      <th class="has-text-right">Revision</th>
      <th>Date</th>
      <th>Operator</th>
      <th class="has-text-centered">Finished</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let assessment of assessments">
      <tr *ngIf="assessment.hasOwnProperty('revision')" [ngClass]="{'is-selected': activeAssessment === assessment}" (click)="toggleActiveAssessment(assessment)">
        <td class="has-text-right">{{assessment.revision}}</td>
        <td>{{assessment.assessmentDate | date:'short'}}</td>
        <td>{{assessment.operator}}</td>
        <td class="has-text-centered" [innerHTML]="assessment.finished | boolean"></td>
      </tr>
    </ng-container>

    </tbody>
  </table>
<!--  <div class="right-align">-->
<!--    <button *ngIf="(assessments[assessments.length - 1].hasOwnProperty('revision') && assessments[assessments.length - 1].hasOwnProperty('finished') && assessments[assessments.length - 1].finished)" title="New Assessment" i18n-title="Finish Assessment" class="button is-light is-small" (click)="createNewAssessment()">-->
<!--      <span class="icon is-small">-->
<!--          <i-feather name="plus" class="icon-smaller"></i-feather>-->
<!--        </span>-->
<!--      <strong i18n>New Assessment</strong>-->
<!--    </button>-->
<!--  </div>-->
</ng-template>

<ng-template #placeholder>
  <div class="content-placeholder" i18n>No plot selected.</div>
</ng-template>

<div class="spacer"></div>

<div *ngIf="activeAssessment" #editorFooter class="editor-footer right-align has-shadow">
  <button [disabled]="!(assessments.length > 0 && assessments[assessments.length - 1].hasOwnProperty('revision') && assessments[assessments.length - 1].hasOwnProperty('finished') && assessments[assessments.length - 1].finished) || isOnWatchList(plotIds[0])" title="New Assessment" i18n-title="Finish Assessment" class="button is-light is-small" (click)="createNewAssessment()">
      <span class="icon is-small">
          <i-feather name="plus" class="icon-smaller"></i-feather>
        </span>
    <strong i18n>New Assessment</strong>
  </button>

  <div class="flex-spacer"></div>
  <button class="button is-light is-small" *ngIf="!isOnWatchList(plotIds[0]); else onWatchList" (click)="addToWatchList(plotIds[0])">
    <i-feather name="plus" class="icon-smaller"></i-feather>
    <strong i18n>Watchlist</strong>
  </button>
  <ng-template #onWatchList>
    <button class="button is-light is-small" (click)="removeFromWatchList(plotIds[0])">
      <i-feather name="minus" class="icon-smaller"></i-feather>
      <strong i18n>Watchlist</strong>
    </button>
  </ng-template>
  <button class="button is-light is-small" [disabled]="state === 'pristine' || state === 'saved' || (cultivation === 'other' && nCodeMeasured === null)" title="Save Assessment" i18n-title="Save Assessment" (click)="sendChanges()">
    <span class="icon is-small">
        <i-feather name="save" class="icon-smaller"></i-feather>
      </span>
  </button>
  <button *ngIf="(activeAssessment.hasOwnProperty('revision') && activeAssessment.hasOwnProperty('finished') && !activeAssessment.finished) || state === 'dirty'; else btnFinished" title="Finish Assessment" i18n-title="Finish Assessment" class="button is-light is-small" (click)="finish()">
    <span class="icon is-small">
        <i-feather name="unlock" class="icon-smaller"></i-feather>
      </span>
  </button>
  <ng-template #btnFinished>
    <button class="button is-light is-small" disabled>
      <span class="icon is-small">
        <i-feather name="lock" class="icon-smaller"></i-feather>
      </span>
    </button>
  </ng-template>
</div>
