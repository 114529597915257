import {Injectable} from '@angular/core';
import {KeycloakTokenParsed} from "keycloak-js";
import {StorageService} from "./storage.service";
import {states} from "../constants";
import {firstValueFrom, map, Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {amsUrl, amsPhotoUrl} from "../events";
import {Router} from "@angular/router";
import {showNotification, stateSet} from '../events';
import {State} from '../model';

/**
 * Model of a Customer i.e. State
 *
 * @property {string} id - A unique id
 * @property {string} name - Abbreviated state name e.g. NI, NW...
 * @property {string} title - Full name of the state
 * @property {Link[]} links - Associated links
 * @property {any} config - (unused) State specific config
 */
export interface Customer {
  id: string;
  name: string;
  title: string;
  links: Link[];
  config: any;
}

/**
 * A link to be used in the Customer model
 *
 * @property {string} href - The link url
 * @property {string} rel - The type of link e.g. ams, photoserver
 * @property {string} title - A descriptive name of the link
 */
export interface Link {
  href: string,
  rel: string,
  title: string
}

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  states: State[] = [];
  activeState: State | undefined;
  selectedStateId: string = "";

  customers$: Observable<Customer[]>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {
    // Retrieve all information about possible customers from well_known_url
    this.customers$ = this.http.get(environment.well_known_url).pipe(map((response: any) => response.customers as Customer[]));
    // Subscribe to stateSet Subject to change state config when user chooses another state
    stateSet.subscribe(state => this.setStateUrlParameter(state));
  }

  /**
   * Determines a number of possible state from a user's token by intersecting the user's assigned states with the
   * applications accessible states.
   *
   * @param tokenParsed - The user's keycloak token
   */
  async setStates(tokenParsed: KeycloakTokenParsed) {
    const roles: string[] | undefined = tokenParsed['realm_access']?.roles;
    if (roles && roles.findIndex(group => group.startsWith('scapi_') || group.startsWith('customer_')) !== -1) {
      const userStates = roles
        .filter(group => group.startsWith('scapi_'))
        .map(group => group.substring(6).toUpperCase());
      const customerStates = roles
        .filter(group => group.startsWith('customer_'))
        .map(group => group.substring(9).toUpperCase());
      console.log('user states', userStates);
      console.log('customer states', customerStates);
      const allStates = userStates.concat(customerStates);
      this.states = states.filter(state => allStates.indexOf(state.id) !== -1);

      // try to read active state from url param
      let stateId: string | null = null;
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search);
        stateId = params.get('customer');
      }

      // if no url parameter was given try to get state from storage
      if (!stateId) {
        stateId = await this.storageService.get('active-state-id') as string | null;
      }

      if (stateId && this.states.find(state => state.id === stateId)) {
        await this.setActiveState(stateId);
      } else {
        if (stateId) {
          showNotification.next({
            title: 'Fehler',
            message: `Sie verfügen nicht über ausreichende Berechtigungen zur Bearbeitung von Schlägen in ${states.find(state => state.id === stateId)?.name}`,
            class: 'is-danger'
          });
        }
        this.selectedStateId = this.states[0].id;
        if (this.states.length === 1) {
          await this.setActiveState(this.selectedStateId);
        }
      }
      console.log(this.states);
    }
  }

  /**
   * Changes the application wide active state to the one specified.
   *
   * @param stateId - The ID of the state to set as active
   */
  async setActiveState(stateId: string) {
    const state = this.states.find(state => state.id === stateId);
    if (state) {
      const newState = this.activeState ? this.activeState.id !== stateId : false;
      this.activeState = state;
      this.activeState.new = newState;
      await this.storageService.set('active-state-id', stateId);
      console.log('Set active state', this.activeState);
      const customers = await firstValueFrom(this.customers$);
      const customer = customers.find(customer => customer.name === state.id);
      if (customer) {
        amsUrl.next(customer.links.find(link => link.rel === 'ams')?.href || '');
        amsPhotoUrl.next(customer.links.find(link => link.rel === 'photoserver')?.href || '');
      }
      stateSet.next(this.activeState as State);
    }
  }

  /**
   * Returns the currently active state.
   *
   * @return Currently active State object
   */
  getActiveState(): State | undefined {
    return this.activeState;
  }

  /**
   * Returns all states but the currently active one. Used to display a list of states to set as active.
   *
   * @return An array of State objects
   */
  getInactiveStates(): State[] {
    return this.states.filter(state => state.id !== this.activeState?.id);
  }

  /**
   * When selecting a state, the state's name is appended to the current url as url parameter.
   *
   * @param state - A State object to use
   * @private
   */
  private async setStateUrlParameter(state: State) {
    const parts = this.router.url.split('?');
    if (parts.length >= 1) {
      const queryParams: any = {'customer': state.id};
      if (!state.new && parts.length >= 2) {
        const params = parts[1].split('&').map(param => param.split('='));
        params.forEach((param, index) => {
          const [key, value] = param;
          if (key !== 'customer') {
            queryParams[key] = value;
          }
        });
      }
      await this.router.navigate([parts[0]], {queryParams});
    }
  }
}
